import { useAuth } from "@clerk/clerk-react";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import LoadingSkeleton from "../components/LoadingSkeleton.js";
import promptData from "../components/PromptData.js";
import PromptPaper from "../components/PromptPaper.js";
import { fetchPrompts, updatePrompts, fetchDefaultPrompts} from "../services/api.js";

const PromptsPage = () => {
  const [userMessages, setUserMessages] = useState({});
  const [systemMessages, setSystemMessages] = useState({});
  const [temperature, setTemperature] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const { getToken } = useAuth();
  const [analysisLanguage, setAnalysisLanguage] = useState(false);

  const loadData = useCallback(async () => {
    setIsLoading(true);
    const token = await getToken();
    const promptsResponse = await fetchPrompts(token);
    const prompts = promptsResponse ?? {
      intentPrompt: { user_message: "", system_message: "" },
      sentimentPrompt: { user_message: "", system_message: "" },
      signalPrompt: { user_message: "", system_message: "" },
      summaryPrompt: { user_message: "", system_message: "" },
      temperature: 1,
      analysisLanguage: false,
    };
    const fetchedUserMessages = {
      "Intent Prompt": prompts.intentPrompt.user_message,
      "Sentiment Prompt": prompts.sentimentPrompt.user_message,
      "Signal Prompt": prompts.signalPrompt.user_message,
      "Summary Prompt": prompts.summaryPrompt.user_message,
    };
    setUserMessages(fetchedUserMessages);
    const fetchedSystemMessages = {
      "Intent Prompt": prompts.intentPrompt.system_message,
      "Sentiment Prompt": prompts.sentimentPrompt.system_message,
      "Signal Prompt": prompts.signalPrompt.system_message,
      "Summary Prompt": prompts.summaryPrompt.system_message,
    };
    setSystemMessages(fetchedSystemMessages);
    setTemperature(prompts.temperature);
    setAnalysisLanguage(prompts.analysisLanguage);
    setIsLoading(false);
  }, [getToken]);

  useEffect(() => {
    loadData();
  }, [getToken, loadData]);

  const handleUserMessageChange = (header, value) => {
    setUserMessages((prevPrompts) => ({ ...prevPrompts, [header]: value }));
  };

  const handleSystemMessageChange = (header, value) => {
    setSystemMessages((prevMessages) => ({ ...prevMessages, [header]: value }));
  };

  const handleTemperatureChange = (e) => {
    const value = parseFloat(e.target.value);
    if (value >= 0.1 && value <= 2) {
      setTemperature(value);
    }
  };

  const handleSaveAll = async () => {
    const token = await getToken();
    setIsLoading(true);
    const response = await updatePrompts(
      userMessages,
      systemMessages,
      temperature,
      analysisLanguage,
      token
    );
    if (response.ok) {
      loadData();
      setIsLoading(false);
    }
  };

  const handleCheckboxChange = (event) => {
    setAnalysisLanguage(event.target.checked);
  };

  const handleResetPrompt = async (header) => {
    const token = await getToken();
  
    const defaultData = await fetchDefaultPrompts(header, token);
  
    setUserMessages((prevMessages) => ({
      ...prevMessages,
      [header]: defaultData.user_message,
    }));
    setSystemMessages((prevMessages) => ({
      ...prevMessages,
      [header]: defaultData.system_message,
    }));
  };
  

  return (
    <Box paddingTop={"40px"} paddingX={"16px"}>
      <Box position="relative" mb={2}>
        <Stack direction={"row"} alignItems={"center"} gap={"6px"}>
          <SmartToyIcon sx={{ width: "2.2rem", height: "2.2rem" }} />
          <Typography fontSize={"2rem"} fontWeight={"bold"}>
            Prompts
          </Typography>
        </Stack>
      </Box>

      {isLoading ? (
        <LoadingSkeleton count={3} padding={"15px"} />
      ) : (
        <Paper
          elevation={3}
          sx={{
            padding: "12px",
            width: "100%",
            borderRadius: 1,
            marginBottom: "15px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "4px",
          }}
        >
          <Stack direction="row" alignItems="center">
            <ThermostatIcon sx={{ width: "1.5rem", height: "1.5rem" }} />
            <Typography variant="h7" fontWeight="bold">
              Temperature:
            </Typography>
            <TextField
              type="number"
              size="small"
              variant="outlined"
              value={temperature}
              onChange={handleTemperatureChange}
              inputProps={{
                min: 0.1,
                max: 2,
                step: 0.1,
              }}
              sx={{
                marginLeft: "8px",
                width: "80px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "16px",
                  "& fieldset": {
                    borderColor: "grey.500",
                  },
                  "&:hover fieldset": {
                    borderColor: "grey.700",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "primary.main",
                  },
                },
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={analysisLanguage}
                  onChange={handleCheckboxChange}
                  color="primary"
                />
              }
              label="Give summary in the ticket's native language"
              sx={{ marginLeft: "18px" }}
            />
          </Stack>
          <Button
            variant="contained"
            sx={{
              borderRadius: "32px",
              textTransform: "none",
              boxShadow: 0,
              ":hover": {
                borderRadius: "32px",
                textTransform: "none",
                boxShadow: 0,
              },
            }}
            onClick={handleSaveAll}
          >
            <Typography color={"white"} sx={{ fontWeight: "bold" }}>
              Save All
            </Typography>
          </Button>
        </Paper>
      )}

      {!isLoading && (
        <Grid container spacing={2} marginBottom={3}>
          {promptData.map((props) => (
            <Grid item xs={12} md={6} key={props.header}>
              <PromptPaper
                header={props.header}
                headerIcon={props.icon}
                immutables={props.immutables}
                userMessageInp={userMessages[props.header]}
                systemMessageInp={systemMessages[props.header]}
                onUserMessageChange={handleUserMessageChange}
                onSystemMessageChange={handleSystemMessageChange}
                onResetPrompt={handleResetPrompt}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default PromptsPage;
