import { Container, CssBaseline, Stack } from "@mui/material";
import React from "react";

import MiniDrawer from "./MiniDrawer";

const Layout = ({ children }) => {
  return (
    <Stack direction="row" width="100%" height="100%">
      <MiniDrawer />
      <Container>
        <CssBaseline />
        {children}
      </Container>
    </Stack>
  );
};

export default Layout;
