import { ClerkLoaded, SignedIn, SignedOut, useAuth } from "@clerk/clerk-react";
import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import AuthPage from "./pages/AuthPage";
import HomePage from "./pages/HomePage";
import IntentCategoryPage from "./pages/IntentCategoryPage";
import TicketPage from "./pages/TicketPage";

import { ThemeProvider, createTheme } from "@mui/material";
import LoadingSkeleton from "./components/LoadingSkeleton";
import { AppContextProvider } from "./hooks/useAppContext";
import PromptsPage from "./pages/PromptsPage";
import SignalCategoryPage from "./pages/SignalCategoryPage";
import { me } from "./services/api";
import TicketPageLayout from "./components/TicketPageLayout";

const theme = createTheme({
  palette: {
    primary: { main: "#fab702" },
    secondary: { main: "#f56d6c" },
    info: { main: "#6990df" },
    background: { default: "#f0f2f5", main: "#fff" },
    drawer: { main: "rgba(0, 0, 0, 0.54)" },
  },
  typography: { fontFamily: "Mulish, sans-serif" },
});

const App = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [value, setValue] = React.useState(undefined);
  const { getToken } = useAuth();

  useEffect(() => {
    setIsLoading(true);
    const fetchOrganization = async () => {
      const token = await getToken();
      const meData = await me(token);
      if (meData) {
        setValue(meData);
      } else {
        setInterval(async () => {
          var response = await me(token);
          var count;
          if (response.user) window.location.reload();
          else {
            count = count + 1;
            if (count > 10) clearInterval();
          }
        }, 200);
      }
      setIsLoading(false);
    };
    fetchOrganization();
  }, [getToken]);

  return (
    <ThemeProvider theme={theme}>
      {isLoading ? (
        <LoadingSkeleton count={6} padding={"32px"} />
      ) : (
        <AppContextProvider initialValue={value}>
          <Routes>
            <Route
              path="/"
              element={
                <ClerkLoaded>
                  <SignedOut>
                    <AuthPage mode="sign-in" />
                  </SignedOut>
                  <SignedIn>
                    <Layout>
                      <HomePage />
                    </Layout>
                  </SignedIn>
                </ClerkLoaded>
              }
            />
            <Route
              path="/tickets"
              element={
                <SignedIn>
                  <TicketPageLayout>
                    <TicketPage />
                  </TicketPageLayout>
                </SignedIn>
              }
            />
            <Route
              path="/intents"
              element={
                <SignedIn>
                  <Layout>
                    <IntentCategoryPage />
                  </Layout>
                </SignedIn>
              }
            />
            <Route
              path="/signals"
              element={
                <SignedIn>
                  <Layout>
                    <SignalCategoryPage />
                  </Layout>
                </SignedIn>
              }
            />
            <Route
              path="/prompts"
              element={
                <SignedIn>
                  <Layout>
                    <PromptsPage />
                  </Layout>
                </SignedIn>
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </AppContextProvider>
      )}
    </ThemeProvider>
  );
};

export default App;
