import { useAuth } from "@clerk/clerk-react";
import AttachmentIcon from "@mui/icons-material/Attachment";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { BarChart } from "@mui/x-charts";
import React, { useState } from "react";
import {
  addComment,
  deleteTicket,
  getIntent,
  getSentiment,
  getSignals,
  getSummary,
} from "../services/api";

const colors = [
  "#1f78b4",
  "#33a02c",
  "#e31a1c",
  "#ff7f00",
  "#6a3d9a",
  "#b15928",
  "#a6cee3",
  "#b2df8a",
  "#fb9a99",
  "#fdbf6f",
  "#cab2d6",
  "#ffff99",
  "#b3e2cd",
  "#fdcdac",
  "#cbd5e8",
];

const TicketCard = ({ ticket, loadTickets }) => {
  const [openComments, setOpenComments] = useState(false);
  const [openAttachments, setOpenAttachments] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [analysisResults, setAnalysisResults] = useState({
    intent: "",
    intentCategory: "",
    sentiment: [],
    signalCategories: [],
    summary: "",
  });
  const { getToken } = useAuth();
  const [openAddComment, setOpenAddComment] = useState(false);
  const [newComment, setNewComment] = useState({
    content: "",
    ownerEmail: "",
    isInternal: false,
  });

  const handleFetchAllAnalysis = async () => {
    const token = await getToken();

    try {
      const [
        intentResponse,
        sentimentResponse,
        signalsResponse,
        summaryResponse,
      ] = await Promise.all([
        getIntent(ticket.id, token),
        getSentiment(ticket.id, token),
        getSignals(ticket.id, token),
        getSummary(ticket.id, token),
      ]);

      setAnalysisResults({
        intent: intentResponse?.intent || "Analysis is not ready yet.",
        intentCategory: intentResponse?.category || "Category not available.",
        sentiment: sentimentResponse?.sentiment || [],
        signalCategories: signalsResponse?.categories || [],
        summary: summaryResponse?.summary || "Analysis is not ready yet.",
      });

      setOpenDetail(true);
    } catch (error) {
      setLoading(false);
      setAnalysisResults({
        intent: "Something went wrong...",
        intentCategory: "Something went wrong...",
        sentiment: [],
        signalCategories: [],
        summary: "Something went wrong...",
      });
      setOpenDetail(true);
    }
  };

  const handleOpenComments = () => {
    setOpenComments(true);
  };

  const handleCloseComments = () => {
    setOpenComments(false);
  };

  const handleOpenAttachments = () => {
    setOpenAttachments(true);
  };

  const handleCloseAttachments = () => {
    setOpenAttachments(false);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
    setAnalysisResults({
      intent: "",
      intentCategory: "",
      sentiment: [],
      signalCategories: [],
      summary: "",
    });
  };

  const handleOpenAddComment = () => {
    setOpenAddComment(true);
  };

  const handleCloseAddComment = () => {
    setOpenAddComment(false);
    setNewComment({ content: "", ownerEmail: "", isInternal: false });
  };

  const handleAddComment = async () => {
    if (!newComment.content || !newComment.ownerEmail) {
      return; // All fields are required
    }
    const token = await getToken();
    await addComment({ ticketId: ticket.id, ...newComment }, token);
    loadTickets();
    handleCloseAddComment();
  };

  const highlightText = (text, highlights, color) => {
    if (!highlights || highlights.length === 0) return text;

    const regex = new RegExp(`(${highlights.join("|")})`, "gi");
    const rgbaColor = `rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(color.slice(5, 7), 16)}, 0.5)`;

    return text.split(regex).map((part, index) =>
      regex.test(part) ? (
        <span key={index} style={{ backgroundColor: rgbaColor }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const getColorForValue = (value) => {
    if (value > 7) return "green";
    if (value > 5) return "yellow";
    if (value > 3) return "orange";
    return "red";
  };

  const dataWithColors = analysisResults.sentiment.map((value, index) => ({
    value,
    color: getColorForValue(value),
  }));

  const handleDeleteTicket = async () => {
    const token = await getToken();
    await deleteTicket(ticket.id, token);
    loadTickets();
  };

  return (
    <Card sx={{ display: "flex", mb: 3, padding: 2, position: "relative" }}>
      <DeleteIcon
        sx={{
          position: "absolute",
          top: 10,
          right: 10,
          cursor: "pointer",
          color: "grey",
        }}
        onClick={handleDeleteTicket}
      />
      {loading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
          }}
        >
          <Skeleton variant="text" width="87%" height={50} />
          <Skeleton variant="text" width="30%" height={30} />
          <Skeleton variant="text" width="100%" height={30} />
          <Skeleton variant="text" width="54%" height={40} />
        </Box>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <CardContent>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
              {ticket.subject || "No Subject"}
              <Chip
                label={ticket.priority || "No Priority"}
                size="small"
                sx={{
                  ml: 3,
                  backgroundColor:
                    ticket.priority === "LOW"
                      ? "lightblue"
                      : ticket.priority === "MEDIUM"
                        ? "orange"
                        : ticket.priority === "HIGH"
                          ? "red"
                          : ticket.priority === "URGENT"
                            ? "purple"
                            : "default",
                  color: "white",
                }}
              />

              <Chip
                label={ticket.status || "No Status"}
                size="small"
                sx={{
                  ml: 1,
                  backgroundColor:
                    ticket.status === "OPEN"
                      ? "red"
                      : ticket.status === "IN_PROGRESS"
                        ? "yellow"
                        : ticket.status === "SOLVED"
                          ? "green"
                          : ticket.status === "CLOSED"
                            ? "grey"
                            : "default",
                  color:
                    ticket.status === "IN_PROGRESS" ||
                    ticket.status === "SOLVED"
                      ? "black"
                      : "white",
                }}
              />
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                mb: 1,
                maxWidth: "80%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
              }}
            >
              {ticket.description || "No Description"}
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
              Requester:{" "}
              <Chip
                label={ticket.requestedBy?.email || "No Requester Email"}
                size="small"
                color="primary"
              />
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
              Assigned Agent:{" "}
              <Chip
                label={ticket.assignedTo?.email || "Unassigned"}
                size="small"
                color="info"
              />
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}>
              <Avatar
                sx={{ bgcolor: "info.main", cursor: "pointer" }}
                onClick={handleOpenComments}
              >
                {ticket.comments?.length || 0}
              </Avatar>
              <Typography
                variant="body2"
                sx={{ cursor: "pointer" }}
                onClick={handleOpenComments}
              >
                View Comments
              </Typography>
              {ticket.attachedFiles?.length > 0 && (
                <AttachmentIcon
                  sx={{ cursor: "pointer", ml: 2 }}
                  onClick={handleOpenAttachments}
                />
              )}
            </Box>
            <Stack justifyContent={"space-between"} direction={"row"}>
              <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleFetchAllAnalysis}
                >
                  View Analysis Results
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenAddComment}
                >
                  Add Comment
                </Button>
              </Box>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography variant="body2">
                  {new Date(ticket.createdAt).toLocaleString()}
                </Typography>
              </Stack>
            </Stack>
          </CardContent>
        </Box>
      )}

      <Dialog
        open={openComments}
        onClose={handleCloseComments}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Comments</DialogTitle>
        <DialogContent dividers>
          <List>
            {ticket.comments?.length !== 0 ? (
              ticket.comments?.map((comment, index) => (
                <ListItem key={index} alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        bgcolor: comment.isInternal ? "info.main" : "info.main",
                      }}
                    >
                      {index + 1}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${comment.owner.email} (${new Date(
                      comment.createdAt
                    ).toLocaleString()})`}
                    secondary={
                      <>
                        <Typography variant="body2" component="span">
                          {comment.content}
                        </Typography>
                        <Chip
                          label={comment.isInternal ? "Internal" : "External"}
                          size="small"
                          sx={{ ml: 1 }}
                        />
                      </>
                    }
                  />
                </ListItem>
              ))
            ) : (
              <Typography variant="body2" color="textSecondary">
                No Comments Available
              </Typography>
            )}
          </List>
        </DialogContent>
      </Dialog>
      {/*Analysis Dialog*/}
      <Dialog
        open={openDetail}
        onClose={handleCloseDetail}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent dividers sx={{ display: "flex", height: "80vh" }}>
          {/* Left Side: Tickets and Comments */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{ height: "100%", overflowY: "auto", flex: 1, pr: 2 }}
          >
            <Typography variant="h6" gutterBottom>
              Ticket Information
            </Typography>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>
              Subject:
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {ticket.subject || "No Subject"}
              <Chip
                label={ticket.priority || "No Priority"}
                size="small"
                sx={{
                  ml: 3,
                  backgroundColor:
                    ticket.priority === "LOW"
                      ? "lightblue"
                      : ticket.priority === "MEDIUM"
                        ? "orange"
                        : ticket.priority === "HIGH"
                          ? "red"
                          : ticket.priority === "URGENT"
                            ? "purple"
                            : "default",
                  color: "white",
                }}
              />

              <Chip
                label={ticket.status || "No Status"}
                size="small"
                sx={{
                  ml: 1,
                  backgroundColor:
                    ticket.status === "OPEN"
                      ? "red"
                      : ticket.status === "IN_PROGRESS"
                        ? "yellow"
                        : ticket.status === "SOLVED"
                          ? "green"
                          : ticket.status === "CLOSED"
                            ? "grey"
                            : "default",
                  color:
                    ticket.status === "IN_PROGRESS" ||
                    ticket.status === "SOLVED"
                      ? "black"
                      : "white",
                }}
              />
            </Typography>

            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>
              Description:
            </Typography>
            <Typography variant="body2">
              {highlightText(
                ticket.description || "No Description",
                hoveredCategory ? hoveredCategory.texts : [],
                hoveredCategory
                  ? colors[hoveredCategory.index % colors.length]
                  : "transparent"
              )}
            </Typography>

            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>
              Requester:
            </Typography>
            <Typography variant="body2">
              {ticket.requestedBy?.email || "No Requester Email"}
            </Typography>

            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>
              Assigned Agent:
            </Typography>
            <Typography variant="body2">
              {ticket.assignedTo?.email || "Unassigned"}
            </Typography>

            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>
              Comments:
            </Typography>
            <List>
              {ticket.comments?.length !== 0 ? (
                ticket.comments?.map((comment, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          bgcolor: comment.isInternal
                            ? "info.main"
                            : "info.main",
                        }}
                      >
                        {index + 1}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${comment.owner.email} (${new Date(
                        comment.createdAt
                      ).toLocaleString()})`}
                      secondary={
                        <>
                          <Typography variant="body2" component="span">
                            {highlightText(
                              comment.content || "No Description",
                              hoveredCategory ? hoveredCategory.texts : [],
                              hoveredCategory
                                ? colors[hoveredCategory.index % colors.length]
                                : "transparent"
                            )}
                          </Typography>
                          <Chip
                            label={comment.isInternal ? "Internal" : "External"}
                            size="small"
                            sx={{ ml: 1 }}
                          />
                        </>
                      }
                    />
                  </ListItem>
                ))
              ) : (
                <Typography variant="body2" color="textSecondary">
                  No Comments Available
                </Typography>
              )}
            </List>
          </Grid>

          <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />

          {/* Right Side: Analysis Results */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{ height: "100%", overflowY: "auto", flex: 1, pl: 2 }}
          >
            <Typography variant="h6" gutterBottom>
              Analysis Results
            </Typography>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Intent:
            </Typography>
            <Box
              sx={{
                display: "inline-block",
                borderRadius: 2,
                padding: 1,
                border: "1px solid",
                borderColor: "grey.200",
                wordWrap: "break-word",
              }}
            >
              <Typography variant="subtitle2">
                {analysisResults.intentCategory}
              </Typography>
              <Box>
                <Chip
                  label={analysisResults.intent}
                  size="small"
                  sx={{
                    backgroundColor: "primary.main",
                    color: "#fff",
                  }}
                />
              </Box>
            </Box>

            {/* Sentiment Section */}
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>
              Sentiment:
            </Typography>
            <Box display="flex" alignItems="center">
              <BarChart
                series={[
                  {
                    data: dataWithColors.map((data) => data.value),
                  },
                ]}
                xAxis={[
                  {
                    scaleType: "band",
                    data: analysisResults.sentiment.map(
                      (_, index) => "Analysis " + (index + 1)
                    ),
                    colorMap: {
                      type: "ordinal",
                      values: analysisResults.sentiment.map(
                        (_, index) => "Analysis " + (index + 1)
                      ),
                      colors: dataWithColors.map((data) => data.color),
                    },
                  },
                ]}
                width={420}
                height={300}
                borderRadius={10}
                yAxis={[
                  {
                    max: 10,
                    min: 0,
                  },
                ]}
              />
            </Box>

            {/* Signals Section */}
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>
              Signals:
            </Typography>
            {analysisResults.signalCategories.length === 0 ? (
              <Typography variant="body2" color="textSecondary">
                No Signals Available
              </Typography>
            ) : (
              <Grid container spacing={1}>
                {analysisResults.signalCategories.map((category, index) => (
                  <Grid item xs="auto" key={index}>
                    <Box
                      sx={{
                        borderRadius: 2,
                        padding: 1,
                        border: "1px solid",
                        borderColor: "grey.200",
                        maxWidth: "100%",
                        wordWrap: "break-word",
                      }}
                      onMouseEnter={() =>
                        setHoveredCategory({ ...category, index })
                      }
                      onMouseLeave={() => setHoveredCategory(null)}
                    >
                      <Typography variant="subtitle2">
                        {category.category}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 1,
                          maxWidth: "100%",
                        }}
                      >
                        {category.signals.map((signal, signalIndex) => (
                          <Chip
                            key={signalIndex}
                            label={signal}
                            size="small"
                            sx={{
                              backgroundColor: colors[index % colors.length],
                              color: "#fff",
                            }}
                          />
                        ))}
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}

            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Summary:
            </Typography>
            <Typography variant="body2">
              {analysisResults.summary || "No Summary Available"}
            </Typography>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openAttachments}
        onClose={handleCloseAttachments}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Attached Files</DialogTitle>
        <DialogContent dividers>
          <List>
            {ticket.attachedFiles.map((file, index) => (
              <ListItem key={index}>
                <ListItemText primary={file} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>

      {/* Dialog for Adding Comments */}
      <Dialog
        open={openAddComment}
        onClose={handleCloseAddComment}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Add Comment</DialogTitle>
        <DialogContent dividers>
          <TextField
            margin="dense"
            label="Comment"
            fullWidth
            required
            multiline
            rows={3}
            value={newComment.content}
            onChange={(e) =>
              setNewComment({ ...newComment, content: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Owner Email"
            type="email"
            fullWidth
            required
            value={newComment.ownerEmail}
            onChange={(e) =>
              setNewComment({ ...newComment, ownerEmail: e.target.value })
            }
          />
          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            <Checkbox
              checked={newComment.isInternal}
              onChange={(e) =>
                setNewComment({ ...newComment, isInternal: e.target.checked })
              }
            />
            <Typography>Internal Comment</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddComment}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddComment}
          >
            Add Comment
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default TicketCard;
