import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const IntentItem = ({ intent, onAccept, onReject }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (e) => {
    if (e) e.stopPropagation(); // Prevent parent click
    setOpen(false);
  };

  const handleDelete = (e) => {
    if (e) e.stopPropagation(); // Prevent parent click
    onReject(intent.id);
    handleClose();
  };

  return (
    <>
      <Card
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          boxShadow: 0,
          borderRadius: 1,
          width: "fit-content",
          flex: "1 0 auto",
          transition: "transform 0.2s",
          "&:hover": {
            transform: "scale(1.05)",
          },
          cursor: "pointer", // Make the card clickable
        }}
        onClick={(e) => {
          e.stopPropagation(); // Prevent triggering the card click
          handleClickOpen();
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={1}
          sx={{ flexGrow: 1 }}
        >
          <Chip
            label={intent.intent}
            sx={{
              fontWeight: "bold",
              backgroundColor: intent.isNew ? "primary.light" : "grey.200",
              color: intent.isNew ? "white" : "text.primary",
              "&:hover": {
                backgroundColor: intent.isNew ? "primary" : "grey.300",
              },
            }}
          />
        </Stack>
        <Stack direction="row" spacing={-1} justifyContent="flex-end">
          {intent.isNew && (
            <>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering the card click
                  onAccept(intent.id);
                }}
                color="success"
                size="small"
              >
                <CheckCircleOutlineIcon />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering the card click
                  onReject(intent.id);
                }}
                color="error"
                size="small"
              >
                <CancelIcon />
              </IconButton>
            </>
          )}
        </Stack>
      </Card>

      {/* Dialog for Confirming Deletion */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Delete Intent</DialogTitle>
        <Typography sx={{ padding: 2 }}>
          Are you sure you want to delete the intent "{intent.intent}"?
        </Typography>
        <DialogActions>
          <Button
            onClick={(e) => {
              handleClose(e);
            }}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              handleDelete(e);
            }}
            color="primary"
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default IntentItem;
