import React, { useState } from 'react';
import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  Typography,
  Stack,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const SignalCategoryFilter = ({ category, selectedSignals, onSignalChange }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleSignalChange = (signalName) => {
    const newSelectedSignals = selectedSignals.includes(signalName)
      ? selectedSignals.filter((signal) => signal !== signalName)
      : [...selectedSignals, signalName];
    onSignalChange('signals', newSelectedSignals);
  };

  return (
    <Box sx={{ marginBottom: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="subtitle2">{category.name}</Typography>
        <IconButton onClick={handleToggle} size="small">
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      <Collapse in={isExpanded}>
        <Stack spacing={0} sx={{ paddingLeft: 2, paddingTop: 1 }}>
          {category.signals && category.signals.length > 0 ? (
            category.signals.map((signal) => (
              <FormControlLabel
                key={signal.id}
                control={
                  <Checkbox
                    checked={selectedSignals.includes(signal.signal)}
                    onChange={() => handleSignalChange(signal.signal)}
                    size="small"
                  />
                }
                label={
                  <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>
                    {signal.signal}
                  </Typography>
                }
                sx={{ 
                  margin: 0,
                  '& .MuiCheckbox-root': {
                    padding: '2px',
                  },
                }}
              />
            ))
          ) : (
            <Typography variant="body2" color="text.secondary">
              No signals in this category
            </Typography>
          )}
        </Stack>
      </Collapse>
    </Box>
  );
};

export default SignalCategoryFilter;