import { Grid } from "@mui/material";
import React from "react";
import TicketCard from "./TicketCard";

const TicketList = ({ tickets, loadTickets }) => {
  return (
    <Grid container spacing={"8px"}>
      {tickets.map((ticket) => (
        <Grid item xs={12} key={ticket.id}>
          <TicketCard ticket={ticket} loadTickets={loadTickets}/>
        </Grid>
      ))}
    </Grid>
  );
};

export default TicketList;
