import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const CreateApiKeyDialog = ({ open, onClose, apiKey }) => {
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(apiKey);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{"New API Key"}</DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Please write down your new API key or copy it now. You won't be able
          to see it again.
        </Typography>
        <TextField
          label="API Key"
          value={apiKey}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <IconButton onClick={handleCopyToClipboard}>
                <ContentCopyIcon />
              </IconButton>
            ),
          }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateApiKeyDialog;
