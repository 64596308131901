import {
  Chip,
  Divider,
  Paper,
  Stack,
  TextField,
  Typography,
  Button
} from "@mui/material";
import React, { useEffect, useState } from "react";

const PromptPaper = ({
  header,
  headerIcon: HeaderIcon,
  immutables,
  userMessageInp,
  systemMessageInp,
  onUserMessageChange,
  onSystemMessageChange,
  onResetPrompt,
}) => {
  const [userMessage, setUserMessage] = useState(userMessageInp);
  const [systemMessage, setSystemMessage] = useState(systemMessageInp);

  useEffect(() => {
    setUserMessage(userMessageInp); 
    setSystemMessage(systemMessageInp); 
  }, [userMessageInp, systemMessageInp]);

  const handleInputChange = (e) => {
    setUserMessage(e.target.value);
    onUserMessageChange(header, e.target.value);
  };

  const handleSystemMessageChange = (e) => {
    setSystemMessage(e.target.value);
    onSystemMessageChange(header, e.target.value);
  };

  return (
    <Paper
      elevation={3}
      sx={{
        padding: "24px",
        width: "100%",
        minWidth: "350px",
        height: "100%",
        borderRadius: 1,
        position: "relative",
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" marginBottom="16px">
        <Stack direction="row" alignItems="center" gap="6px">
          {HeaderIcon && <HeaderIcon sx={{ width: "2rem", height: "2rem" }} />}
          <Typography variant="h7" fontWeight="bold">
            {header}
          </Typography>
        </Stack>
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            onResetPrompt(header); 
          }}
          sx={{
            textTransform: "none",
            position: "absolute",
            right: 16,
            top: 16,
          }}
        >
          Reset to Default
        </Button>
      </Stack>
      <Divider sx={{ marginBottom: "16px" }} />
      <Stack direction="column" spacing={1} marginBottom="16px">
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ fontWeight: "bold" }}
        >
          System Message:
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={3}
          variant="outlined"
          value={systemMessage}
          onChange={handleSystemMessageChange}
          sx={{ marginBottom: "16px" }}
          InputProps={{
            sx: {
              color: "#008000",
              fontFamily: "monospace",
              fontSize: "0.9rem",
            },
          }}
        />
      </Stack>
      <Typography
        variant="body2"
        color="textSecondary"
        sx={{ fontWeight: "bold", marginBottom: "8px" }}
      >
        User Message:
      </Typography>
      <TextField
        fullWidth
        multiline
        rows={6}
        variant="outlined"
        value={userMessage}
        onChange={handleInputChange}
        sx={{ marginBottom: "16px" }}
        InputProps={{
          sx: {
            color: "#008000",
            fontFamily: "monospace",
            fontSize: "0.9rem",
          },
        }}
      />
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Below are the inputs:
      </Typography>
      {Object.entries(immutables).map(([label, value]) => (
        <Typography
          variant="body2"
          color="textSecondary"
          gutterBottom
          key={label}
        >
          {label}:
          <Chip
            label={value}
            sx={{
              marginLeft: "3px",
              backgroundColor: "grey.100",
              color: "text.primary",
              fontFamily: "monospace",
            }}
          />
        </Typography>
      ))}
    </Paper>
  );
};

export default PromptPaper;
