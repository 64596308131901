import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ClerkProvider } from "@clerk/clerk-react";
import { BrowserRouter } from "react-router-dom";
import { dark } from '@clerk/themes'
import simple from '@clerk/themes/'

const clerkAppearance = {
  baseTheme: simple
  
};

const root = ReactDOM.createRoot(document.getElementById("root"));

const publishableKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
root.render(
  <ClerkProvider
    publishableKey={publishableKey}
    appearance={clerkAppearance}
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ClerkProvider>
);

reportWebVitals();