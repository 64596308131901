import React from "react";
import LoadingSkeleton from "../components/LoadingSkeleton";
import OrganizationCreationFlow from "../components/OrganizationCreationFlow";
import { useAppContext } from "../hooks/useAppContext";
import OrganizationPage from "./OrganizationPage";

const HomePage = () => {
  const { value } = useAppContext();

  if (!value) {
    return <LoadingSkeleton count={6} padding={"15px"} />;
  } else {
    if (!value.organization) {
      return <OrganizationCreationFlow />;
    } else {
      return <OrganizationPage appContext={value}></OrganizationPage>;
    }
  }
};

export default HomePage;
