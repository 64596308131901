import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SensorsIcon from "@mui/icons-material/Sensors";
import SummarizeIcon from "@mui/icons-material/Summarize";

const promptData = [
  {
    header: "Intent Prompt",
    icon: MenuBookIcon,
    immutables: {
      Subject: "{{ticketSubject}}",
      Description: "{{ticketDescription}}",
      "List of intents and their assigned categories": "{{orgIntents}}",
      Attachments: "{{ticketAttachments}}",
    },
  },
  {
    header: "Sentiment Prompt",
    icon: FormatColorTextIcon,
    immutables: {
      Subject: "{{ticketSubject}}",
      Description: "{{ticketDescription}}",
      "Comments of the ticket": "{{requesterComment}}, {{agentComment}}",
      Attachments: "{{ticketAttachments}}",
    },
  },
  {
    header: "Signal Prompt",
    icon: SensorsIcon,
    immutables: {
      Subject: "{{ticketSubject}}",
      Description: "{{ticketDescription}}",
      "List of signals and their assigned categories": "{{orgSignals}}",
      "Comments of the ticket": "{{requesterComment}}, {{agentComment}}",
      Attachments: "{{ticketAttachments}}",
    },
  },
  {
    header: "Summary Prompt",
    icon: SummarizeIcon,
    immutables: {
      Subject: "{{ticketSubject}}",
      Description: "{{ticketDescription}}",
      "Comments of the ticket": "{{requesterComment}}, {{agentComment}}",
      Attachments: "{{ticketAttachments}}",
    },
  },
];

export default promptData;
