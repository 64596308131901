import { useAuth } from "@clerk/clerk-react";
import AddIcon from "@mui/icons-material/Add";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import IntentCategoryCard from "../components/IntentCategoryCard";
import LoadingSkeleton from "../components/LoadingSkeleton";
import {
  acceptCategory,
  acceptIntent,
  createIntent,
  createIntentCategory,
  fetchCategories,
  fetchIntents,
  rejectCategory,
  rejectIntent,
} from "../services/api"; // Assuming acceptCategory and rejectCategory are part of your API service

const IntentCategoryPage = () => {
  const [intentsData, setIntentsData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [newCategory, setNewCategory] = useState("");

  const { getToken } = useAuth();

  const loadData = useCallback(async () => {
    setIsLoading(true);
    const token = await getToken();

    const [categoriesResponse, intentsResponse] = await Promise.all([
      fetchCategories(token),
      fetchIntents(token),
    ]);

    const categories = categoriesResponse.categories || [];
    const intents = intentsResponse.intents || [];

    const categorizedIntents = categories.reduce((acc, category) => {
      acc[category.id] = {
        ...category,
        intents: [],
      };
      return acc;
    }, {});

    intents.forEach((intent) => {
      if (categorizedIntents[intent.category.id]) {
        categorizedIntents[intent.category.id].intents.push(intent);
      }
    });

    setCategoriesData(Object.values(categorizedIntents));
    setIsLoading(false);
  }, [getToken]);

  useEffect(() => {
    loadData();
  }, [getToken, loadData]);

  const handleAcceptIntent = async (intentId) => {
    const token = await getToken();
    await acceptIntent(intentId, token);
    setCategoriesData(
      categoriesData.map((category) => ({
        ...category,
        intents: category.intents.map((intent) =>
          intent.id === intentId ? { ...intent, isNew: false } : intent
        ),
      }))
    );
  };

  const handleRejectIntent = async (intentId) => {
    const token = await getToken();
    setCategoriesData(
      categoriesData.map((category) => ({
        ...category,
        intents: category.intents.filter((intent) => intent.id !== intentId),
      }))
    );
    await rejectIntent(intentId, token);
  };

  const handleAcceptCategory = async (categoryId) => {
    const token = await getToken();
    await acceptCategory(categoryId, token);
    setCategoriesData(
      categoriesData.map((category) =>
        category.id === categoryId ? { ...category, isNew: false } : category
      )
    );
  };

  const handleRejectCategory = async (categoryId) => {
    const token = await getToken();
    setCategoriesData(
      categoriesData.filter((category) => category.id !== categoryId)
    );
    await rejectCategory(categoryId, token);
  };

  const handleCreateIntentCategory = async () => {
    const token = await getToken();
    await createIntentCategory(newCategory, token);
    setOpenCategoryDialog(false);
    setNewCategory("");
    loadData(); // Reload data after creation
  };

  const handleCreateIntent = async (categoryId, intentName) => {
    const token = await getToken();
    await createIntent(categoryId, intentName, token);
    loadData(); // Reload data after creation
  };

  const handleOpenCategoryDialog = () => {
    setOpenCategoryDialog(true);
  };

  const handleCloseCategoryDialog = () => {
    setOpenCategoryDialog(false);
    setNewCategory("");
  };

  return (
    <Box paddingTop={"40px"}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        gap={"6px"}
        marginBottom={"20px"}
      >
        <MenuBookIcon sx={{ width: "2.2rem", height: "2.2rem" }}></MenuBookIcon>
        <Typography fontSize={"2rem"} fontWeight={"bold"}>
          Intents
        </Typography>
      </Stack>
      {isLoading ? (
        <LoadingSkeleton count={6} padding={"32px"} />
      ) : (
        <Grid container spacing={2} paddingBottom={"40px"}>
          {categoriesData.map((category) => (
            <Grid item xs={12} key={category.id}>
              <IntentCategoryCard
                category={category}
                onAcceptCategory={handleAcceptCategory}
                onRejectCategory={handleRejectCategory}
                onAcceptIntent={handleAcceptIntent}
                onRejectIntent={handleRejectIntent}
                onCreateIntent={handleCreateIntent}
              />
            </Grid>
          ))}
        </Grid>
      )}
      <Fab
        color="primary"
        aria-label="add"
        onClick={handleOpenCategoryDialog}
        sx={{ position: "fixed", bottom: 25, right: 25 }}
      >
        <AddIcon />
      </Fab>

      {/* Dialog for Adding a New Category */}
      <Dialog open={openCategoryDialog} onClose={handleCloseCategoryDialog}>
        <DialogTitle>Add a new intent category</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Category Name"
            type="text"
            fullWidth
            variant="outlined"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCategoryDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleCreateIntentCategory} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default IntentCategoryPage;
