import { useAuth } from "@clerk/clerk-react";
import AddIcon from "@mui/icons-material/Add";
import SensorsIcon from "@mui/icons-material/Sensors";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import LoadingSkeleton from "../components/LoadingSkeleton";
import SignalCategoryCard from "../components/SignalCategoryCard";
import {
  acceptSignal,
  acceptSignalCategory,
  createSignal,
  createSignalCategory,
  fetchSignalCategories,
  fetchSignals,
  rejectSignal,
  rejectSignalCategory,
} from "../services/api";

const SignalCategoryPage = () => {
  const { getToken } = useAuth();
  const [categoriesData, setCategoriesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [newCategory, setNewCategory] = useState("");

  const loadData = useCallback(async () => {
    setIsLoading(true);
    const token = await getToken();

    const [categoriesResponse, signalsResponse] = await Promise.all([
      fetchSignalCategories(token),
      fetchSignals(token),
    ]);

    const categories = categoriesResponse.categories || [];
    const signals = signalsResponse.signals || [];

    const categorizedSignals = categories.reduce((acc, category) => {
      acc[category.id] = {
        ...category,
        signals: [],
      };
      return acc;
    }, {});

    signals.forEach((signal) => {
      if (categorizedSignals[signal.category.id]) {
        categorizedSignals[signal.category.id].signals.push(signal);
      }
    });

    setCategoriesData(Object.values(categorizedSignals));
    setIsLoading(false);
  }, [getToken]);

  useEffect(() => {
    loadData();
  }, [getToken, loadData]);

  const handleAcceptSignal = async (signalId) => {
    const token = await getToken();
    await acceptSignal(signalId, token);
    setCategoriesData(
      categoriesData.map((category) => ({
        ...category,
        signals: category.signals.map((signal) =>
          signal.id === signalId ? { ...signal, isNew: false } : signal
        ),
      }))
    );
  };

  const handleRejectSignal = async (signalId) => {
    const token = await getToken();
    setCategoriesData(
      categoriesData.map((category) => ({
        ...category,
        signals: category.signals.filter((signal) => signal.id !== signalId),
      }))
    );
    await rejectSignal(signalId, token);
  };

  const handleAcceptCategory = async (categoryId) => {
    const token = await getToken();
    await acceptSignalCategory(categoryId, token);
    setCategoriesData(
      categoriesData.map((category) =>
        category.id === categoryId ? { ...category, isNew: false } : category
      )
    );
  };

  const handleRejectCategory = async (categoryId) => {
    const token = await getToken();
    setCategoriesData(
      categoriesData.filter((category) => category.id !== categoryId)
    );
    rejectSignalCategory(categoryId, token);
  };

  const handleCreateSignalCategory = async () => {
    const token = await getToken();
    createSignalCategory(newCategory, token);
    setOpenCategoryDialog(false);
    setNewCategory("");
    loadData(); // Reload data after creation
  };

  const handleCreateSignal = async (categoryId, signalName) => {
    const token = await getToken();
    await createSignal(categoryId, signalName, token);
    loadData(); // Reload data after creation
  };

  const handleOpenCategoryDialog = () => {
    setOpenCategoryDialog(true);
  };

  const handleCloseCategoryDialog = () => {
    setOpenCategoryDialog(false);
    setNewCategory("");
  };

  return (
    <Box paddingTop={"40px"}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        gap={"6px"}
        marginBottom={"20px"}
      >
        <SensorsIcon sx={{ width: "2.2rem", height: "2.2rem" }} />
        <Typography fontSize={"2rem"} fontWeight={"bold"}>
          Signals
        </Typography>
      </Stack>
      {isLoading ? (
        <LoadingSkeleton count={6} padding={"32px"} />
      ) : (
        <Grid container spacing={2} paddingBottom={"40px"}>
          {categoriesData.map((category) => (
            <Grid item xs={12} key={category.id}>
              <SignalCategoryCard
                category={category}
                onAcceptCategory={handleAcceptCategory}
                onRejectCategory={handleRejectCategory}
                onAcceptSignal={handleAcceptSignal}
                onRejectSignal={handleRejectSignal}
                onCreateSignal={handleCreateSignal}
              />
            </Grid>
          ))}
        </Grid>
      )}
      <Fab
        color="primary"
        aria-label="add"
        onClick={handleOpenCategoryDialog}
        sx={{ position: "fixed", bottom: 25, right: 25 }}
      >
        <AddIcon />
      </Fab>
      {/* Dialog for Adding a New Category */}
      <Dialog open={openCategoryDialog} onClose={handleCloseCategoryDialog}>
        <DialogTitle>Add a new signal category</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Category Name"
            type="text"
            fullWidth
            variant="outlined"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCategoryDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleCreateSignalCategory} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SignalCategoryPage;
