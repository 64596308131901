import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import {
  searchUsers,
  deleteUserFromOrg,
  changeRoleOfUser,
} from "../../services/api";
import { useAuth } from "@clerk/clerk-react";
import LoadingSkeleton from "../LoadingSkeleton";
import OrgMembersList from "../OrgMembersList";
import InviteUserDialog from "../InviteUserDialog";

const UsersTab = ({ appContext }) => {
  const [users, setUsers] = useState([]);
  const [inviteds, setInviteds] = useState([]);
  const [isUsersLoading, setIsUsersLoading] = useState(true);
  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
  const [search, setSearch] = useState("");

  const { getToken } = useAuth();

  const loadUsers = useCallback(async () => {
    setIsUsersLoading(true);
    const token = await getToken();

    const payload = {
      search: search,
      pageNumber: 0,
      pageSize: 1000,
    };

    const fetchedUsers = await searchUsers(token, payload);
    setUsers(fetchedUsers.users);
    setInviteds(fetchedUsers.invitedUserEmails);

    setIsUsersLoading(false);
  }, [getToken, search]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const handleDeleteUser = async (userId) => {
    const token = await getToken();
    const response = await deleteUserFromOrg({ userId, token });
    if (response.ok) {
      setUsers(users.filter((user) => user.id !== userId));
    }
  };

  const handleRoleChange = async (user, newRole) => {
    const token = await getToken();
    const response = await changeRoleOfUser({
      userId: user.id,
      role: newRole,
      token,
    });
    if (response.ok) {
      user.role = newRole;
      setUsers(users.map((x) => (x.id === user.id ? user : x)));
    }
  };

  return (
    <Paper
      elevation={1}
      sx={{ padding: "16px 38px 12px 38px", borderRadius: 1 }}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Manage users in your organization
        </Typography>
        <Button
          variant="contained"
          disabled={appContext.role !== "org:admin"}
          sx={{ borderRadius: "32px", textTransform: "none", boxShadow: 0 }}
          onClick={() => setIsInviteDialogOpen(true)}
        >
          Invite Users
        </Button>
      </Stack>
      <Box
        sx={{ display: "flex", justifyContent: "center", marginBottom: "16px" }}
      >
        <TextField
          onChange={(e) => setSearch(e.target.value)}
          type="search"
          placeholder="Search users"
          variant="outlined"
          size="small"
          sx={{
            mt: 2,
            width: "500px",
            backgroundColor: "#f0f0f0",
            borderRadius: "30px",
            "& .MuiOutlinedInput-root fieldset": {
              borderColor: "#ccc",
              borderRadius: "30px",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {isUsersLoading ? (
        <LoadingSkeleton count={3} padding={"15px"} />
      ) : (
        <OrgMembersList
          appContext={appContext}
          users={users}
          invited={inviteds}
          onDelete={handleDeleteUser}
          onRoleChange={handleRoleChange}
        />
      )}
      <InviteUserDialog
        open={isInviteDialogOpen}
        onClose={() => {
          setIsInviteDialogOpen(false);
          loadUsers();
        }}
      />
    </Paper>
  );
};

export default UsersTab;
