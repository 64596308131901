import React from "react";
import { SignIn, SignUp } from "@clerk/clerk-react";
import { Container, Box } from "@mui/material";

const AuthPage = ({ mode }) => {
  return (
    <Container maxWidth="sm">
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
        {mode === "sign-up" ? <SignUp /> : <SignIn />}
      </Box>
    </Container>
  );
};

export default AuthPage;