import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import React from "react";

const getRandomPercentage = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const LoadingSkeleton = ({ count, padding = 0 }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        padding,
        alignSelf: "stretch",
      }}
    >
      {Array.from({ length: count }).map((_, index) => (
        <Skeleton
          key={index}
          variant="rounded"
          width={`${getRandomPercentage(10, 90)}%`}
          height={64}
        />
      ))}
    </Box>
  );
};

export default LoadingSkeleton;
