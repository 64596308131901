import React, { useState } from "react";
import { Box, Stack, Tabs, Tab, Typography } from "@mui/material";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import UsersTab from "../components/tabs/UsersTab";
import ApiKeysTab from "../components/tabs/ApiKeysTab";
import WebhooksTab from "../components/tabs/WebhooksTab";

const OrganizationPage = ({ appContext }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box paddingTop={"40px"}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        gap={"8px"}
        marginBottom={"20px"}
      >
        <HomeWorkIcon
          sx={{ width: "2.2rem", height: "2.2rem", marginBottom: "-2px" }}
        />
        <Typography fontSize={"2rem"} fontWeight={"bold"}>
          {appContext.organization.name}
        </Typography>
      </Stack>

      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        aria-label="organization page tabs"
      >
        <Tab label="Users" />
        <Tab label="API Keys" />
        <Tab label="Webhooks" />
      </Tabs>

      {selectedTab === 0 && <UsersTab appContext={appContext} />}
      {selectedTab === 1 && <ApiKeysTab />}
      {selectedTab === 2 && <WebhooksTab />}
    </Box>
  );
};

export default OrganizationPage;
