
import { createContext, useContext, useState } from 'react';

const AppContext = createContext(undefined);
export const AppContextProvider = ({
  children,
  initialValue,
}) => {
  const [value, setValue] = useState(initialValue);
  return <AppContext.Provider value={{value, setValue}}>{children}</AppContext.Provider>;
};
export const useAppContext = () => {
  const context = useContext(AppContext);
  return context;
};