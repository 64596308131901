import React, { useState } from 'react';
import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  Typography,
  Stack,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const IntentCategoryFilter = ({ category, selectedIntents, onIntentChange }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleIntentChange = (intentName) => {
    const newSelectedIntents = selectedIntents.includes(intentName)
      ? selectedIntents.filter((intent) => intent !== intentName)
      : [...selectedIntents, intentName];
    onIntentChange('intents', newSelectedIntents);
  };

  return (
    <Box sx={{ marginBottom: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="subtitle2">{category.name}</Typography>
        <IconButton onClick={handleToggle} size="small">
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      <Collapse in={isExpanded}>
        <Stack spacing={0} sx={{ paddingLeft: 2, paddingTop: 1 }}>
          {category.intents && category.intents.length > 0 ? (
            category.intents.map((intent) => (
              <FormControlLabel
                key={intent.id}
                control={
                  <Checkbox
                    checked={selectedIntents.includes(intent.intent)}
                    onChange={() => handleIntentChange(intent.intent)}
                    size="small"
                  />
                }
                label={
                  <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>
                    {intent.intent}
                  </Typography>
                }
                sx={{ 
                  margin: 0,
                  '& .MuiCheckbox-root': {
                    padding: '2px',
                  },
                }}
              />
            ))
          ) : (
            <Typography variant="body2" color="text.secondary">
              No intents in this category
            </Typography>
          )}
        </Stack>
      </Collapse>
    </Box>
  );
};

export default IntentCategoryFilter;