import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import SignalItem from "./SignalItem";

const SignalCategoryCard = ({
  category,
  onAcceptCategory,
  onRejectCategory,
  onAcceptSignal,
  onRejectSignal,
  onCreateSignal,
}) => {
  const [openSignalDialog, setOpenSignalDialog] = useState(false);
  const [newSignal, setNewSignal] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleOpenSignalDialog = () => {
    setOpenSignalDialog(true);
  };

  const handleCloseSignalDialog = () => {
    setOpenSignalDialog(false);
    setNewSignal("");
  };

  const handleSubmitSignal = () => {
    onCreateSignal(category.id, newSignal);
    handleCloseSignalDialog();
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleDeleteCategory = () => {
    onRejectCategory(category.id);
    handleCloseDeleteDialog();
  };

  return (
    <>
      <Card
        sx={{
          position: "relative",
          padding: 2,
          boxShadow: 3,
          borderRadius: category.isNew ? 10 : 1,
          border: category.isNew ? "2px solid" : "none",
          borderColor: "primary.main",
          transition: "transform 0.2s",
          "&:hover": {
            transform: "scale(1.03)",
          },
          cursor: "pointer",
        }}
        onClick={(e) => {
          e.stopPropagation();
          handleOpenDeleteDialog();
        }}
      >
        <CardContent>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            marginBottom={2}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {category.name}
            </Typography>
            {category.isNew && (
              <Stack direction="row" spacing={-1}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onAcceptCategory(category.id);
                  }}
                  color="success"
                  size="small"
                >
                  <CheckCircleOutlineIcon />
                </IconButton>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onRejectCategory(category.id);
                  }}
                  color="error"
                  size="small"
                >
                  <CancelIcon />
                </IconButton>
              </Stack>
            )}
          </Stack>
          <Grid container spacing={2} paddingX={"5px"} maxWidth={"1000px"}>
            {category.signals.length > 0 ? (
              category.signals.map((signal) => (
                <Grid item key={signal.id}>
                  <SignalItem
                    signal={signal}
                    onAccept={onAcceptSignal}
                    onReject={onRejectSignal}
                  />
                </Grid>
              ))
            ) : (
              <Typography variant="body2" color="textSecondary">
                No signals in this category
              </Typography>
            )}
          </Grid>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleOpenSignalDialog();
            }}
            color="primary"
            sx={{
              position: "absolute",
              top: 16, // Stays at the top
              right: 16, // Stays on the right
              width: 56,
              height: 56,
              borderRadius: "50%",
              "&:hover": {
                color: "primary.light",
              },
            }}
          >
            <AddCircleIcon sx={{ fontSize: 38 }} />
          </IconButton>
        </CardContent>
      </Card>

      {/* Dialog for Adding a New Signal */}
      <Dialog open={openSignalDialog} onClose={handleCloseSignalDialog}>
        <DialogTitle>Add a new signal to {category.name}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Signal"
            type="text"
            fullWidth
            variant="outlined"
            value={newSignal}
            onChange={(e) => setNewSignal(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSignalDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmitSignal} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for Confirming Deletion */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Delete Category</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete the category "{category.name}"?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDeleteCategory} color="primary">
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SignalCategoryCard;
