const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const getHeaders = (token) => ({
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
});
const getHeadersMultipart = (token) => ({
  Authorization: `Bearer ${token}`,
});
export const me = async (token) => {
  try {
    const response = await fetch(`${API_BASE_URL}/me`, {
      headers: getHeaders(token),
    });
    return response.json();
  } catch (error) {
    console.error("Error fetching me:", error);
    return null;
  }
};

export const getIntent = async (ticketId, token) => {
  try {
    const response = await fetch(`${API_BASE_URL}/intent/${ticketId}`, {
      headers: getHeaders(token),
    });
    if (!response.ok) {
      throw new Error("Failed to fetch intent");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching intent:", error);
    return [];
  }
};

export const getSentiment = async (ticketId, token) => {
  try {
    const response = await fetch(`${API_BASE_URL}/sentiment/${ticketId}`, {
      headers: getHeaders(token),
    });
    if (!response.ok) {
      throw new Error("Failed to fetch sentiment");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching sentiment:", error);
    return [];
  }
};

export const getSignals = async (ticketId, token) => {
  try {
    const response = await fetch(`${API_BASE_URL}/signals/${ticketId}`, {
      headers: getHeaders(token),
    });
    if (!response.ok) {
      throw new Error("Failed to fetch signals");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching signals:", error);
    return [];
  }
};

export const getSummary = async (ticketId, token) => {
  try {
    const response = await fetch(`${API_BASE_URL}/summary/${ticketId}`, {
      headers: getHeaders(token),
    });
    if (!response.ok) {
      throw new Error("Failed to fetch summary");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching summary:", error);
    return [];
  }
};

export const fetchTickets = async (
  token,
  pageNumber = 0,
  pageSize = 20,
  sortBy = "createdAt",
  sortDirection = "ASC",
  search = "",
  filters = []
) => {
  try {
    const response = await fetch(`${API_BASE_URL}/tickets/search`, {
      method: "POST",
      headers: getHeaders(token),
      body: JSON.stringify({
        pageNumber,
        pageSize,
        sortBy,
        sortDirection,
        search,
        filters,
        includeComments: true,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch tickets");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching tickets:", error);
    return { tickets: [], totalTickets: 0 };
  }
};
export const createTicket = async (newTicket, token) => {
  try {
    const response = await fetch(`${API_BASE_URL}/tickets`, {
      method: "POST",
      headers: getHeaders(token),
      body: JSON.stringify(newTicket),
    });

    if (!response.ok) {
      throw new Error("Failed to create ticket");
    }

    return;
  } catch (error) {
    console.error("Error creating ticket:", error);
    return null;
  }
};

export const uploadAttachments = async (files, token) => {
  const formData = new FormData();
  files.forEach((file) => formData.append("files", file));

  try {
    const response = await fetch(`${API_BASE_URL}/tickets/attachments`, {
      method: "POST",
      headers: getHeadersMultipart(token),
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Failed to upload attachments");
    }

    const data = await response.json();
    return data.attachmentReferenceIds;
  } catch (error) {
    console.error("Error uploading attachments:", error);
    return [];
  }
};

export const searchUsers = async (token, payload) => {
  const response = await fetch(`${API_BASE_URL}/organizations/users/search`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    throw new Error("Failed to search users");
  }

  return response.json();
};

export const changeRoleOfUser = async ({ userId, role, token }) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/organizations/users/${userId}/${role}`,
      {
        method: "PATCH",
        headers: getHeaders(token),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to change role");
    }
    return response;
  } catch (error) {
    console.error("Error while changing role:", error);
    return [];
  }
};

export const deleteUserFromOrg = async ({ userId, token }) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/organizations/users/${userId}`,
      {
        method: "DELETE",
        headers: getHeaders(token),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to change role");
    }
    return response;
  } catch (error) {
    console.error("Error while changing role:", error);
    return [];
  }
};

export const sendInvitation = async ({ email, role, token }) => {
  try {
    const response = await fetch(`${API_BASE_URL}/organizations/users`, {
      method: "POST",
      headers: getHeaders(token),
      body: JSON.stringify({ email, role }),
    });

    if (!response.ok) {
      throw new Error("Failed to invite user");
    }
    return response;
  } catch (error) {
    console.error("Error while inviting user:", error);
    return [];
  }
};

export const DEFAULT_USER_ID = "mock-user-id";

export const createOrganization = async ({ name, token }) => {
  try {
    const response = await fetch(`${API_BASE_URL}/organizations`, {
      method: "POST",
      headers: getHeaders(token),
      body: JSON.stringify({ name }),
    });

    if (!response.ok) {
      throw new Error("Failed to create org");
    }
    return response;
  } catch (error) {
    console.error("Error while creating org:", error);
    return [];
  }
};

export const fetchIntents = async (token) => {
  try {
    const response = await fetch(`${API_BASE_URL}/organizations/intents`, {
      method: "GET",
      headers: getHeaders(token),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch intents");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching intents:", error);
    return [];
  }
};

export const acceptIntent = async (intentId, token) => {
  try {
    await fetch(`${API_BASE_URL}/organizations/intents`, {
      method: "PATCH",
      headers: getHeaders(token),
      body: JSON.stringify({ orgIntentId: intentId, action: "accept" }),
    });
  } catch (error) {
    console.error("Error accepting intent:", error);
  }
};

export const rejectIntent = async (intentId, token) => {
  try {
    await fetch(`${API_BASE_URL}/organizations/intents`, {
      method: "PATCH",
      headers: getHeaders(token),
      body: JSON.stringify({ orgIntentId: intentId, action: "reject" }),
    });
  } catch (error) {
    console.error("Error rejecting intent:", error);
  }
};

export const createIntentCategory = async (name, token) => {
  try {
    await fetch(`${API_BASE_URL}/organizations/intents/categories`, {
      method: "POST",
      headers: getHeaders(token),
      body: JSON.stringify({ name }),
    });
  } catch (error) {
    console.error("Error creating intent category:", error);
  }
};

export const createIntent = async (categoryId, intent, token) => {
  try {
    await fetch(`${API_BASE_URL}/organizations/intents`, {
      method: "POST",
      headers: getHeaders(token),
      body: JSON.stringify({ orgIntentCategoryId: categoryId, intent }),
    });
  } catch (error) {
    console.error("Error creating intent:", error);
  }
};
export const fetchCategories = async (token) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/organizations/intents/categories`,
      {
        method: "GET",
        headers: getHeaders(token),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch categories");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching categories:", error);
    return [];
  }
};
export const acceptCategory = async (categoryId, token) => {
  try {
    await fetch(`${API_BASE_URL}/organizations/intents/categories`, {
      method: "PATCH",
      headers: getHeaders(token),
      body: JSON.stringify({
        orgIntentCategoryId: categoryId,
        action: "accept",
      }),
    });
  } catch (error) {
    console.error("Error accepting category:", error);
  }
};

export const rejectCategory = async (categoryId, token) => {
  try {
    await fetch(`${API_BASE_URL}/organizations/intents/categories`, {
      method: "PATCH",
      headers: getHeaders(token),
      body: JSON.stringify({
        orgIntentCategoryId: categoryId,
        action: "reject",
      }),
    });
  } catch (error) {
    console.error("Error rejecting category:", error);
  }
};

export const fetchSignals = async (token) => {
  try {
    const response = await fetch(`${API_BASE_URL}/organizations/signals`, {
      method: "GET",
      headers: getHeaders(token),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch signals");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching signals:", error);
    return [];
  }
};

export const acceptSignal = async (signalId, token) => {
  try {
    await fetch(`${API_BASE_URL}/organizations/signals`, {
      method: "PATCH",
      headers: getHeaders(token),
      body: JSON.stringify({ orgSignalId: signalId, action: "accept" }),
    });
  } catch (error) {
    console.error("Error accepting signal:", error);
  }
};

export const rejectSignal = async (signalId, token) => {
  try {
    await fetch(`${API_BASE_URL}/organizations/signals`, {
      method: "PATCH",
      headers: getHeaders(token),
      body: JSON.stringify({ orgSignalId: signalId, action: "reject" }),
    });
  } catch (error) {
    console.error("Error rejecting signal:", error);
  }
};

export const createSignalCategory = async (name, token) => {
  try {
    await fetch(`${API_BASE_URL}/organizations/signals/categories`, {
      method: "POST",
      headers: getHeaders(token),
      body: JSON.stringify({ name }),
    });
  } catch (error) {
    console.error("Error creating signal category:", error);
  }
};

export const createSignal = async (categoryId, signal, token) => {
  try {
    await fetch(`${API_BASE_URL}/organizations/signals`, {
      method: "POST",
      headers: getHeaders(token),
      body: JSON.stringify({ orgSignalCategoryId: categoryId, signal }),
    });
  } catch (error) {
    console.error("Error creating signal:", error);
  }
};

export const fetchSignalCategories = async (token) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/organizations/signals/categories`,
      {
        method: "GET",
        headers: getHeaders(token),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch signal categories");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching signal categories:", error);
    return [];
  }
};

export const acceptSignalCategory = async (categoryId, token) => {
  try {
    await fetch(`${API_BASE_URL}/organizations/signals/categories`, {
      method: "PATCH",
      headers: getHeaders(token),
      body: JSON.stringify({
        orgSignalCategoryId: categoryId,
        action: "accept",
      }),
    });
  } catch (error) {
    console.error("Error accepting signal category:", error);
  }
};

export const rejectSignalCategory = async (categoryId, token) => {
  try {
    await fetch(`${API_BASE_URL}/organizations/signals/categories`, {
      method: "PATCH",
      headers: getHeaders(token),
      body: JSON.stringify({
        orgSignalCategoryId: categoryId,
        action: "reject",
      }),
    });
  } catch (error) {
    console.error("Error rejecting signal category:", error);
  }
};

export const fetchPrompts = async (token) => {
  try {
    const response = await fetch(`${API_BASE_URL}/prompt`, {
      method: "GET",
      headers: getHeaders(token),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch prompts");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching prompts:", error);
    return [];
  }
};

export const updatePrompts = async (
  userMessages,
  systemMessages,
  temperature,
  analysisLanguage,
  token
) => {
  try {
    const response = await fetch(`${API_BASE_URL}/prompt`, {
      method: "PATCH",
      headers: getHeaders(token),
      body: JSON.stringify({
        intentPrompt: {
          user_message: userMessages["Intent Prompt"],
          system_message: systemMessages["Intent Prompt"],
        },
        sentimentPrompt: {
          user_message: userMessages["Sentiment Prompt"],
          system_message: systemMessages["Sentiment Prompt"],
        },
        signalPrompt: {
          user_message: userMessages["Signal Prompt"],
          system_message: systemMessages["Signal Prompt"],
        },
        summaryPrompt: {
          user_message: userMessages["Summary Prompt"],
          system_message: systemMessages["Summary Prompt"],
        },
        temperature: temperature,
        analysisLanguage: analysisLanguage,
      }),
    });
    if (!response.ok) {
      throw new Error("Failed to update prompts.");
    }
    return response;
  } catch (error) {
    console.error("Failed to update prompts.", error);
  }
};

export const fetchDefaultPrompts = async (header, token) => {
  try {
    const typeMap = {
      "Intent Prompt": "intent",
      "Sentiment Prompt": "sentiment",
      "Signal Prompt": "signal",
      "Summary Prompt": "summary",
    };

    const response = await fetch(
      `${API_BASE_URL}/prompt/default?type=${typeMap[header]}`,
      {
        method: "GET",
        headers: getHeaders(token),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch default prompts.");
    }

    const data = await response.json();
    return {
      user_message: data.userMessage,
      system_message: data.systemMessage,
    };
  } catch (error) {
    console.error("Error fetching default prompts:", error);
    return {
      user_message: "",
      system_message: "",
    };
  }
};

export const deleteTicket = async (ticketId, token) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/tickets?ticketId=${ticketId}`,
      {
        method: "DELETE",
        headers: getHeaders(token),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to delete ticket");
    }

    return response;
  } catch (error) {
    console.error("Error deleting ticket:", error);
    return null;
  }
};

export const addComment = async (commentPayload, token) => {
  try {
    const response = await fetch(`${API_BASE_URL}/tickets/comments`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(commentPayload),
    });

    if (!response.ok) {
      throw new Error("Failed to add comment");
    }

    return response;
  } catch (error) {
    console.error("Error adding comment:", error);
    return null;
  }
};

export const createApiKey = async (token) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api-keys`, {
      method: "POST",
      headers: getHeaders(token),
    });

    if (!response.ok) {
      throw new Error("Failed to create API key");
    }

    return response.json();
  } catch (error) {
    console.error("Error creating API key:", error);
    return null;
  }
};

// API functions

export const fetchWebhooks = async (token) => {
  try {
    const response = await fetch(`${API_BASE_URL}/webhooks`, {
      method: "GET",
      headers: getHeaders(token),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch webhooks");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching webhooks:", error);
    return [];
  }
};

export const createWebhook = async (token) => {
  try {
    const response = await fetch(`${API_BASE_URL}/webhooks`, {
      method: "POST",
      headers: getHeaders(token),
    });

    if (!response.ok) {
      throw new Error("Failed to create webhook");
    }
    return response.json();
  } catch (error) {
    console.error("Error creating webhook:", error);
    return null;
  }
};

export const deleteWebhook = async (webhookId, token) => {
  try {
    const response = await fetch(`${API_BASE_URL}/webhooks/${webhookId}`, {
      method: "DELETE",
      headers: getHeaders(token),
    });

    if (!response.ok) {
      throw new Error("Failed to delete webhook");
    }
    return response;
  } catch (error) {
    console.error("Error deleting webhook:", error);
    return null;
  }
};

export const fetchApiKeys = async (token) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api-keys`, {
      method: "GET",
      headers: getHeaders(token),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch API keys");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching API keys:", error);
    return [];
  }
};

export const deleteApiKey = async (apiKeyId, token) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api-keys/${apiKeyId}`, {
      method: "DELETE",
      headers: getHeaders(token),
    });

    if (!response.ok) {
      throw new Error("Failed to delete API key");
    }
    return response;
  } catch (error) {
    console.error("Error deleting API key:", error);
    return null;
  }
};

export const runAIFilter = async (userQuery, token) => {
  try {
    const response = await fetch(`${API_BASE_URL}/tickets/ai-filter`, {
      method: 'POST',
      headers: getHeaders(token),
      body: JSON.stringify({ userQuery }),
    });

    if (!response.ok) {
      throw new Error('Failed to run AI filter');
    }

    return response.json();
  } catch (error) {
    console.error('Error running AI filter:', error);
    throw error;
  }
};
