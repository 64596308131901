import { useAuth } from "@clerk/clerk-react";
import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { createOrganization, me } from "../services/api";
import LoadingSkeleton from "./LoadingSkeleton";
import { useEffect } from "react";

const OrganizationCreationFlow = () => {
  const [organizationName, setOrganizationName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { getToken } = useAuth();

  useEffect(() => {
    setIsLoading(true);
    const fetchMe = async () => {
      const token = await getToken();
      const meData = await me(token);
      if (meData.message) {
        window.location.reload();
      }
      setIsLoading(false);
    };
    fetchMe();
  }, [getToken]);

  const handleCreateOrganization = async () => {
    setIsLoading(true);
    const token = await getToken();
    const response = await createOrganization({
      name: organizationName,
      token: token,
    });
    if (response.ok) {
      var count = 0;
      setInterval(async () => {
        var response = await me(token);
        if (response.organization) window.location.reload();
        else {
          count = count + 1;
          if (count > 10) clearInterval();
        }
      }, 500);
    }
  };

  return (
    <Box
      padding={"40px"}
      sx={{
        backgroundColor: "white",
        marginTop: "80px",
        borderRadius: 3,
      }}
    >
      {isLoading ? (
        <LoadingSkeleton count={3} padding={"15px"} />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography variant="h6">Create Organization</Typography>
          <TextField
            label="Organization Name"
            variant="outlined"
            value={organizationName}
            onChange={(e) => setOrganizationName(e.target.value)}
            required
          />
          <Button
            sx={{
              variant: "contained",
              backgroundColor: "primary.main",
              borderRadius: "32px",
              textTransform: "none",
              boxShadow: 0,
              ":hover": {
                borderRadius: "32px",
                textTransform: "none",
                boxShadow: 0,
                backgroundColor: "primary.dark",
              },
            }}
            onClick={handleCreateOrganization}
          >
            <Typography
              variant="h7"
              color={"white"}
              sx={{ fontWeight: "bold" }}
            >
              {"Create Organization"}
            </Typography>
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default OrganizationCreationFlow;
