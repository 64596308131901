import DeleteIcon from "@mui/icons-material/Delete";
import {
  Avatar,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";

const roles = [
  { key: "Admin", value: "org:admin" },
  { key: "Member", value: "org:member" },
];

const OrgMembersList = ({
  appContext,
  users,
  invited = [],
  onDelete,
  onRoleChange,
}) => {
  return (
    <List>
      {users?.map((user) => (
        <ListItem
          key={user.id}
          sx={{
            borderTop: "0.5px solid #e0e0e0",
            borderBottom: "0.5px solid #e0e0e0",
            py: 1, // Reduces padding on the y-axis (top and bottom)
            display: "flex",
            flexDirection: { xs: "column", sm: "row" }, // Stack vertically on xs screens
            alignItems: { xs: "flex-start", sm: "center" },
            justifyContent: "space-between",
          }}
        >
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={6} display="flex" alignItems="center">
              <ListItemAvatar>
                <Avatar src={user.avatarUrl} alt={user.name} />
              </ListItemAvatar>
              <ListItemText
                primary={user.name}
                secondary={user.email}
                sx={{ ml: 2 }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              display="flex"
              justifyContent={{ xs: "flex-start", sm: "flex-end" }}
              alignItems="center"
              sx={{ mt: { xs: 1, sm: 0 } }} // Adds margin-top on smaller screens to prevent overlap
            >
              <FormControl
                sx={{ mr: 1 }}
                size="small"
                disabled={
                  appContext.role !== "org:admin" ||
                  appContext.userId === user.id
                }
              >
                <Select
                  value={user.role}
                  onChange={(e) => onRoleChange(user, e.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "User role" }}
                  size="small"
                  variant="outlined"
                  sx={{ width: 140 }}
                >
                  {roles?.map((role) => (
                    <MenuItem key={role.key} value={role.value}>
                      {role.key}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <IconButton
                disabled={
                  appContext.role !== "org:admin" ||
                  appContext.userId === user.id
                }
                edge="end"
                aria-label="delete"
                onClick={() => onDelete(user.id)}
                size="small"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </ListItem>
      ))}

      {invited?.length > 0 ? (
        invited.map((invitee) => (
          <ListItem
            key={invitee}
            sx={{
              borderTop: "0.5px solid #e0e0e0",
              borderBottom: "0.5px solid #e0e0e0",
              py: 1,
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: { xs: "flex-start", sm: "center" },
              justifyContent: "space-between",
              opacity: 0.6,
            }}
          >
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} sm={6} display="flex" alignItems="center">
                <ListItemAvatar>
                  <Avatar>{invitee.charAt(0)}</Avatar>{" "}
                </ListItemAvatar>
                <ListItemText
                  primary={invitee}
                  secondary={`${invitee}`}
                  sx={{ ml: 2 }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                display="flex"
                justifyContent={{ xs: "flex-start", sm: "flex-end" }}
                alignItems="center"
                sx={{ mt: { xs: 1, sm: 0 } }}
              >
                <Typography variant="body2" sx={{ mr: 2 }}>
                  Pending Invitation
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        ))
      ) : (
        <Typography
          variant="body2"
          sx={{ textAlign: "center", mt: 2, color: "text.secondary" }}
        >
          No pending invitations.
        </Typography>
      )}
    </List>
  );
};

export default OrgMembersList;
