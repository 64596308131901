import { useAuth } from "@clerk/clerk-react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { sendInvitation } from "../services/api";

const roles = [
  { key: "Member", value: "org:member" },
  { key: "Admin", value: "org:admin" },
];

const InviteUserDialog = ({ open, onClose }) => {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState(roles[0].value);
  const [isSending, setIsSending] = useState(false);
  const { getToken } = useAuth();

  const handleSendInvitation = async () => {
    setIsSending(true);
    try {
      const token = await getToken();
      const response = await sendInvitation({
        email: email,
        role: role,
        token: token,
      });
      if (response.ok) {
        alert("Invitation sent successfully");
        setEmail("");
        setRole(roles[0].value); // Reset role
        onClose(); // Close the dialog
      } else {
        alert("Failed to send invitation");
      }
    } finally {
      setIsSending(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          margin: "auto",
        },
      }}
    >
      <DialogTitle>Invite User</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Email Address"
          type="email"
          fullWidth
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <FormControl fullWidth margin="dense">
          <InputLabel>Role</InputLabel>
          <Select
            value={role}
            onChange={(e) => setRole(e.target.value)}
            label="Role"
            required
          >
            {roles.map((roleOption) => (
              <MenuItem key={roleOption.key} value={roleOption.value}>
                {roleOption.key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSendInvitation} disabled={isSending}>
          Send Invitation
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InviteUserDialog;
