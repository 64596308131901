import { useAuth } from "@clerk/clerk-react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { uploadAttachments } from "../services/api";

const CommentField = ({
  comment,
  index,
  onChange,
  onDelete,
  onCheckboxChange,
}) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      gap: 1,
      border: "1px solid",
      borderRadius: 2,
      borderColor: "grey.300",
      padding: "16px 6px 16px 16px",
    }}
  >
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          flexGrow: 1,
        }}
      >
        <TextField
          label={`Comment ${index + 1} Content`}
          variant="outlined"
          value={comment.content}
          onChange={(e) => onChange(index, "content", e.target.value)}
          multiline
          rows={2}
          required={index === 0}
          fullWidth
        />
        <TextField
          label={`Comment ${index + 1} Owner Email`}
          variant="outlined"
          value={comment.ownerEmail}
          onChange={(e) => onChange(index, "ownerEmail", e.target.value)}
          required
          fullWidth
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={comment.isInternal}
              onChange={(e) => onCheckboxChange(index, e)}
              color="primary"
            />
          }
          label={`Comment ${index + 1} Is Internal`}
        />
      </Box>
      <IconButton
        onClick={() => onDelete(index)}
        sx={{ color: "secondary.main", padding: 0, marginLeft: "6px" }}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  </Box>
);

const TicketForm = ({ onCreateTicket, open, handleClose }) => {
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [externalId, setExternalId] = useState("");
  const [source, setSource] = useState("Zendesk");
  const [requestedByEmail, setRequestedByEmail] = useState("");
  const [assignedToEmail, setAssignedToEmail] = useState("");
  const [comments, setComments] = useState([]);
  const [attachments, setAttachments] = useState([]);

  const { getToken } = useAuth();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleAddComment = () => {
    setComments([
      ...comments,
      { content: "", isInternal: false, ownerEmail: "" },
    ]);
  };

  const handleCommentChange = (index, field, value) => {
    const newComments = [...comments];
    newComments[index][field] = value;
    setComments(newComments);
  };

  const handleCheckboxChange = (index, event) => {
    const newComments = [...comments];
    newComments[index].isInternal = event.target.checked;
    setComments(newComments);
  };

  const handleDeleteComment = (index) => {
    const newComments = comments.filter((_, i) => i !== index);
    setComments(newComments);
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 20) {
      alert("You can only upload up to 20 files.");
      return;
    }
    setAttachments(files);
  };

  const handleDeleteFile = (index) => {
    const newAttachments = attachments.filter((_, i) => i !== index);
    setAttachments(newAttachments);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!subject || !description || !requestedByEmail) {
      alert("Please fill in all required fields");
      return;
    }

    let attachmentIds = [];
    const token = await getToken();
    if (attachments.length > 0) {
      attachmentIds = await uploadAttachments(attachments, token);
    }

    const newTicket = {
      subject,
      description,
      source,
      externalId: externalId || undefined,
      requestedBy: {
        email: requestedByEmail,
        source,
      },
      assignedTo: assignedToEmail
        ? {
            email: assignedToEmail,
            source,
          }
        : undefined,
      comments: comments.map((comment) => ({
        content: comment.content,
        isInternal: comment.isInternal,
        owner: {
          email: comment.ownerEmail,
          source,
        },
      })),
      attachmentReferences: attachmentIds,
    };

    onCreateTicket(newTicket);
    setSubject("");
    setDescription("");
    setExternalId("");
    setRequestedByEmail("");
    setAssignedToEmail("");
    setComments([]);
    setAttachments([]);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>Create Ticket</DialogTitle>
      <DialogContent sx={{ padding: 3 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            gap: 3,
            height: "100%",
            maxHeight: "70vh",
            overflowY: "auto",
            paddingRight: 3,
            paddingTop: 1,
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              ...(isSmallScreen
                ? {}
                : {
                    position: "sticky",
                    top: 0,
                    alignSelf: "start",
                  }),
            }}
          >
            <TextField
              label="Subject"
              variant="outlined"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
              fullWidth
            />
            <TextField
              label="Description"
              variant="outlined"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
              multiline
              rows={4}
              fullWidth
            />
            <TextField
              label="External ID"
              variant="outlined"
              value={externalId}
              onChange={(e) => setExternalId(e.target.value)}
              fullWidth
            />
            <FormControl fullWidth>
              <InputLabel id="source-label" shrink>
                Source
              </InputLabel>
              <Select
                labelId="source-label"
                value={source}
                onChange={(e) => setSource(e.target.value)}
                required
                label="Source"
              >
                <MenuItem value="Zendesk">Zendesk</MenuItem>
                <MenuItem value="Freshdesk">Freshdesk</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Requested By Email"
              variant="outlined"
              value={requestedByEmail}
              onChange={(e) => setRequestedByEmail(e.target.value)}
              required
              fullWidth
            />
            <TextField
              label="Assigned To Email"
              variant="outlined"
              value={assignedToEmail}
              onChange={(e) => setAssignedToEmail(e.target.value)}
              fullWidth
            />
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            {comments.map((comment, index) => (
              <CommentField
                key={index}
                index={index}
                comment={comment}
                onChange={handleCommentChange}
                onDelete={handleDeleteComment}
                onCheckboxChange={handleCheckboxChange}
              />
            ))}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                width: "100%",
              }}
            >
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleAddComment}
                sx={{ flexGrow: 1 }}
              >
                Add Comment
              </Button>

              <Button
                variant="outlined"
                color="info"
                component="label"
                sx={{ flexGrow: 1 }}
              >
                Upload Files
                <input
                  type="file"
                  hidden
                  multiple
                  onChange={handleFileChange}
                />
              </Button>
            </Box>
            <Box sx={{ marginTop: 2 }}>
              {attachments.map((file, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 1,
                    padding: 1,
                    border: "1px solid",
                    borderColor: "grey.300",
                    borderRadius: 1,
                  }}
                >
                  <Typography>{file.name}</Typography>
                  <IconButton
                    onClick={() => handleDeleteFile(index)}
                    sx={{
                      color: "info.main",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ paddingRight: 3, paddingBottom: 3 }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Create Ticket
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TicketForm;
