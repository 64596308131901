import { Box, CssBaseline, Stack } from "@mui/material";
import React from "react";
import MiniDrawer from "./MiniDrawer";
const TicketPageLayout = ({ children }) => {
  return (
    <Stack direction="row" width="100%" height="100%">
      <MiniDrawer />
      <Box
        sx={{
          flexGrow: 1,
          paddingLeft: 2,
          paddingRight: 2,
        }}
      >
        <CssBaseline />
        {children}
      </Box>
    </Stack>
  );
};

export default TicketPageLayout;
