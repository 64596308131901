import { UserButton } from "@clerk/clerk-react";
import BusinessIcon from "@mui/icons-material/Business";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import MenuIcon from "@mui/icons-material/Menu";
import MenuBookIcon from "@mui/icons-material/MenuBook"; // New Icon Import
import SensorsIcon from "@mui/icons-material/Sensors";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../hooks/useAppContext";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme, open }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: `${!open ? "center" : "space-between"}`,
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const DrawerFooter = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "auto",
  borderTop: `1px solid ${theme.palette.divider}`,
  flexDirection: "row",
  width: "100%",
}));

const DrawerFooterContent = ({ value, open }) => {
  return (
    <Box sx={{ width: "100%", height: "64px" }}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{ width: "100%", px: 1.5 }}
      >
        <UserButton />
        <Stack
          direction="column"
          spacing={-0.5}
          sx={{ flexGrow: 1, visibility: open ? "visible" : "hidden" }}
        >
          <Typography noWrap>{value.name}</Typography>
          <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
            {value.email}
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <HomeWorkIcon color="drawer" />
            <Typography sx={{ fontWeight: "bold" }}>
              {value.organization.name}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer({ children }) {
  const { value } = useAppContext();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  const getListItemStyles = (path) => ({
    minHeight: 48,
    justifyContent: open ? "initial" : "center",
    px: 2.5,
    backgroundColor: location.pathname === path ? "primary.light" : "inherit",
    "&:hover": {
      backgroundColor: location.pathname === path ? "primary.main" : "grey.100",
    },
  });

  if (!value.organization) {
    return <></>;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader open={open}>
          {open && (
            <Box sx={{ display: "flex", alignItems: "center", paddingLeft: 1 }}>
              <Box
                component="img"
                src="/logo.png"
                alt="Actioner Logo"
                sx={{
                  width: 37.4,
                  height: 33.3333,
                  objectFit: "contain",
                  borderRadius: 1,
                  backgroundColor: "transparent",
                }}
              />
              <Typography
                variant="h6"
                sx={{ marginLeft: 2, fontWeight: "bold" }}
              >
                Actioner
              </Typography>
            </Box>
          )}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={open ? handleDrawerClose : handleDrawerOpen}
          >
            {open ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={getListItemStyles("/")}
              onClick={() => handleNavigation("/")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText
                primary="Organization"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={getListItemStyles("/tickets")}
              onClick={() => handleNavigation("/tickets")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <ConfirmationNumberIcon />
              </ListItemIcon>
              <ListItemText primary="Tickets" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={getListItemStyles("/intents")}
              onClick={() => handleNavigation("/intents")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <MenuBookIcon />
              </ListItemIcon>
              <ListItemText primary="Intents" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={getListItemStyles("/signals")}
              onClick={() => handleNavigation("/signals")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <SensorsIcon />
              </ListItemIcon>
              <ListItemText primary="Signals" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={getListItemStyles("/prompts")}
              onClick={() => handleNavigation("/prompts")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <SmartToyIcon />
              </ListItemIcon>
              <ListItemText primary="Prompts" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List>
        <DrawerFooter open={open}>
          <DrawerFooterContent value={value} open={open} />
        </DrawerFooter>
      </Drawer>
    </Box>
  );
}
