import { useAuth } from "@clerk/clerk-react";
import AddIcon from "@mui/icons-material/Add";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  Fab,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import IntentCategoryFilter from "../components/IntentCategoryFilter";
import LoadingSkeleton from "../components/LoadingSkeleton";
import SignalCategoryFilter from "../components/SignalCategoryFilter";
import TicketForm from "../components/TicketForm";
import TicketList from "../components/TicketList";
import {
  createTicket,
  fetchCategories,
  fetchIntents,
  fetchSignalCategories,
  fetchSignals,
  fetchTickets,
  runAIFilter,
} from "../services/api";

const TicketPage = () => {
  const [tickets, setTickets] = useState([]);
  const [isTicketsLoading, setIsTicketsLoading] = useState(true);
  const [isTicketFormOpen, setTicketFormOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize] = useState(20);
  const [totalTickets, setTotalTickets] = useState(0);
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    status: "",
    priority: "",
    requesterEmail: "",
    assigneeEmail: "",
    intents: [],
    signals: [],
  });
  const { getToken } = useAuth();
  const [isPriorityExpanded, setIsPriorityExpanded] = useState(false);
  const [isStatusExpanded, setIsStatusExpanded] = useState(false);
  const [intentCategories, setIntentCategories] = useState([]);
  const [isIntentsExpanded, setIsIntentsExpanded] = useState(false);
  const [signalCategories, setSignalCategories] = useState([]);
  const [isSignalsExpanded, setIsSignalsExpanded] = useState(false);
  const [isSortingExpanded, setIsSortingExpanded] = useState(false);
  const [isFilterExpanded, setIsFilterExpanded] = useState(true);
  const [aiFilterQuery, setAiFilterQuery] = useState("");
  const [isAIFilterExpanded, setIsAIFilterExpanded] = useState(false);
  const [isAIFilterFocused, setIsAIFilterFocused] = useState(false);
  const [isAIFilterLoading, setIsAIFilterLoading] = useState(false);

  const stackRef = useRef(null);
  const [stackHeight, setStackHeight] = useState(0);

  useEffect(() => {
    if (!stackRef.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === stackRef.current) {
          setStackHeight(entry.target.offsetHeight);
        }
      }
    });

    resizeObserver.observe(stackRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const loadTickets = useCallback(async () => {
    setIsTicketsLoading(true);
    const token = await getToken();

    // Fetch intent categories, intents, signal categories, and signals
    const [
      categoriesResponse,
      intentsResponse,
      signalCategoriesResponse,
      signalsResponse,
    ] = await Promise.all([
      fetchCategories(token),
      fetchIntents(token),
      fetchSignalCategories(token),
      fetchSignals(token),
    ]);

    const categories = categoriesResponse.categories || [];
    const intents = intentsResponse.intents || [];

    const categorizedIntents = categories.reduce((acc, category) => {
      acc[category.id] = {
        ...category,
        intents: [],
      };
      return acc;
    }, {});

    intents.forEach((intent) => {
      if (categorizedIntents[intent.category.id] && !intent.isNew) {
        categorizedIntents[intent.category.id].intents.push(intent);
      }
    });

    // Filter out categories with no non-new intents
    const filteredIntentCategories = Object.values(categorizedIntents).filter(
      (category) => category.intents.length > 0
    );

    setIntentCategories(filteredIntentCategories);

    // Process signal categories and signals
    const signalCategories = signalCategoriesResponse.categories || [];
    const signals = signalsResponse.signals || [];

    const categorizedSignals = signalCategories.reduce((acc, category) => {
      acc[category.id] = {
        ...category,
        signals: [],
      };
      return acc;
    }, {});

    signals.forEach((signal) => {
      if (categorizedSignals[signal.category.id] && !signal.isNew) {
        categorizedSignals[signal.category.id].signals.push(signal);
      }
    });

    // Filter out categories with no non-new signals
    const filteredSignalCategories = Object.values(categorizedSignals).filter(
      (category) => category.signals.length > 0
    );

    setSignalCategories(filteredSignalCategories);

    const appliedFilters = [
      filters.status?.length
        ? { type: "status", values: filters.status }
        : null,
      filters.priority?.length
        ? { type: "priority", values: filters.priority }
        : null,
      filters.requesterEmail
        ? { type: "requestedBy.email", values: [filters.requesterEmail] }
        : null,
      filters.assigneeEmail
        ? { type: "assignedTo.email", values: [filters.assigneeEmail] }
        : null,
      filters.sentiment &&
      (filters.sentiment[0] !== "" || filters.sentiment[1] !== "")
        ? {
            type: "sentiment",
            values: [filters.sentiment[0] || "", filters.sentiment[1] || ""],
          }
        : null,
      filters.intents?.length
        ? { type: "intents", values: filters.intents }
        : null,
      filters.signals?.length
        ? { type: "signals", values: filters.signals }
        : null,
    ].filter((filter) => filter !== null);

    const fetchedTickets = await fetchTickets(
      token,
      pageNumber,
      pageSize,
      sortBy,
      sortDirection,
      search,
      appliedFilters
    );

    setTickets(fetchedTickets.tickets);
    setTotalTickets(fetchedTickets.totalTickets);
    setIsTicketsLoading(false);
    setIsAIFilterLoading(false);
  }, [getToken, pageNumber, pageSize, sortBy, sortDirection, search, filters]);

  useEffect(() => {
    loadTickets();
  }, [loadTickets]);

  const handleCreateTicket = async (newTicket) => {
    const token = await getToken();
    await createTicket(newTicket, token);
    loadTickets();
  };

  const handleOpenTicketForm = () => setTicketFormOpen(true);
  const handleCloseTicketForm = () => setTicketFormOpen(false);

  const handleNextPage = () => {
    if (pageNumber < Math.ceil(totalTickets / pageSize) - 1) {
      setPageNumber((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (pageNumber > 0) {
      setPageNumber((prevPage) => prevPage - 1);
    }
  };

  const handleSentimentChange = (event, newValue) => {
    handleFilterChange("sentiment", newValue);
  };

  const handleSortChange = (event) => setSortBy(event.target.value);
  const handleSortDirectionChange = (event) =>
    setSortDirection(event.target.value);
  const handleSearchChange = (event) => setSearch(event.target.value);

  const handleFilterChange = (field, value) => {
    setFilters((prevFilters) => {
      if (field === "intents" || field === "signals") {
        return {
          ...prevFilters,
          [field]: value,
        };
      } else {
        return {
          ...prevFilters,
          [field]: value,
        };
      }
    });
  };

  const handlePriorityToggle = () => {
    setIsPriorityExpanded(!isPriorityExpanded);
  };

  const handleStatusToggle = () => {
    setIsStatusExpanded(!isStatusExpanded);
  };

  const handleIntentsToggle = () => {
    setIsIntentsExpanded(!isIntentsExpanded);
  };

  const handleSignalsToggle = () => {
    setIsSignalsExpanded(!isSignalsExpanded);
  };

  const handleSortingToggle = () => {
    setIsSortingExpanded(!isSortingExpanded);
  };

  const toggleFilter = () => {
    setIsFilterExpanded(!isFilterExpanded);
  };

  const handleRemoveFilter = (filterType, value) => {
    if (filterType === "sentiment") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        sentiment: [0, 10], // Reset to default range
      }));
    } else if (filterType === "search") {
      setSearch(""); // Clear the search
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [filterType]: prevFilters[filterType].filter((v) => v !== value),
      }));
    }
  };

  const handleClearAllFilters = () => {
    setFilters({
      status: [],
      priority: [],
      requesterEmail: "",
      assigneeEmail: "",
      intents: [],
      signals: [],
      sentiment: [0, 10],
    });
    setSearch("");
    setAiFilterQuery(""); // Clear the AI filter query
  };

  const handleAIFilterChange = (event) => {
    setAiFilterQuery(event.target.value);
  };

  const handleAIFilterToggle = () => {
    setIsAIFilterExpanded(!isAIFilterExpanded);
  };

  const handleAIFilterFocus = () => {
    setIsAIFilterFocused(true);
  };

  const handleAIFilterBlur = () => {
    setIsAIFilterFocused(false);
  };

  const handleRunAIFilter = async () => {
    setIsAIFilterLoading(true);
    try {
      const token = await getToken();
      const response = await runAIFilter(aiFilterQuery, token);

      // Clear existing filters
      setFilters({
        status: [],
        priority: [],
        requesterEmail: "",
        assigneeEmail: "",
        intents: [],
        signals: [],
        sentiment: [0, 10], // Reset to default range
      });
      setSearch(""); // Clear the search

      // Helper function to check if a filter is specified
      const isFilterSpecified = (filter) => {
        if (Array.isArray(filter)) {
          return filter.length > 0 && filter.some((item) => item !== "");
        }
        return filter != null && filter !== "";
      };

      // Apply new filters from the AI response
      setFilters((prevFilters) => ({
        ...prevFilters,
        status: isFilterSpecified(response.status)
          ? response.status.filter((s) => s !== "")
          : [],
        priority: isFilterSpecified(response.priority)
          ? response.priority.filter((p) => p !== "")
          : [],
        intents: isFilterSpecified(response.intents)
          ? response.intents.filter((i) => i !== "")
          : [],
        signals: isFilterSpecified(response.signals)
          ? response.signals.filter((s) => s !== "")
          : [],
        sentiment: isFilterSpecified(response.sentiment)
          ? response.sentiment
          : [0, 10],
      }));

      // Trigger a new search with the updated filter
    } catch (error) {
      console.error("Error running AI filter:", error);
      setIsAIFilterLoading(false);
      // You might want to show an error message to the user here
    }
  };

  const isSentimentFilterActive =
    filters.sentiment &&
    (filters.sentiment[0] !== 0 || filters.sentiment[1] !== 10);

  return (
    <Box display="flex" flexDirection="column">
      <Stack
        ref={stackRef}
        direction={"row"}
        alignItems={"center"}
        gap={"6px"}
        flexWrap="wrap"
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 5,
          backgroundColor: "background.default",
          padding: "30px 0 10px",
        }}
      >
        <IconButton
          onClick={toggleFilter}
          sx={{
            padding: 0,
            marginRight: 1,
          }}
        >
          {isFilterExpanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
        <ConfirmationNumberIcon
          sx={{ width: "2.2rem", height: "2.2rem", marginBottom: "-3px" }}
        />
        <Typography fontSize={"2rem"} fontWeight={"bold"}>
          Tickets
        </Typography>
        {/* Display Search filter if active */}
        {search && (
          <Chip
            key="search"
            label={`Search: ${search}`}
            onDelete={() => handleRemoveFilter("search")}
            deleteIcon={<CloseIcon />}
            size="small"
            sx={{ marginLeft: 1 }}
          />
        )}
        {/* Display selected Priority filters */}
        {filters.priority &&
          filters.priority.map((priority) => (
            <Chip
              key={`priority-${priority}`}
              label={`Priority: ${priority.charAt(0) + priority.slice(1).toLowerCase()}`}
              onDelete={() => handleRemoveFilter("priority", priority)}
              deleteIcon={<CloseIcon />}
              size="small"
              sx={{ marginLeft: 1 }}
            />
          ))}
        {/* Display selected Status filters */}
        {filters.status &&
          filters.status.map((status) => (
            <Chip
              key={`status-${status}`}
              label={`Status: ${status.charAt(0) + status.slice(1).toLowerCase().replace("_", " ")}`}
              onDelete={() => handleRemoveFilter("status", status)}
              deleteIcon={<CloseIcon />}
              size="small"
              sx={{ marginLeft: 1 }}
            />
          ))}
        {/* Display Sentiment filter if active */}
        {isSentimentFilterActive && (
          <Chip
            key="sentiment"
            label={`Sentiment: ${filters.sentiment[0]}-${filters.sentiment[1]}`}
            onDelete={() => handleRemoveFilter("sentiment")}
            deleteIcon={<CloseIcon />}
            size="small"
            sx={{ marginLeft: 1 }}
          />
        )}
        {/* Display selected Intents filters */}
        {filters.intents &&
          filters.intents.map((intent) => (
            <Chip
              key={`intent-${intent}`}
              label={`Intents: ${intent}`}
              onDelete={() => handleRemoveFilter("intents", intent)}
              deleteIcon={<CloseIcon />}
              size="small"
              sx={{ marginLeft: 1 }}
            />
          ))}
        {/* Display selected Signals filters */}
        {filters.signals &&
          filters.signals.map((signal) => (
            <Chip
              key={`signal-${signal}`}
              label={`Signals: ${signal}`}
              onDelete={() => handleRemoveFilter("signals", signal)}
              deleteIcon={<CloseIcon />}
              size="small"
              sx={{ marginLeft: 1 }}
            />
          ))}
      </Stack>
      <Box display="flex" flexDirection="row">
        <Box position="relative">
          <Collapse
            in={isFilterExpanded}
            orientation="horizontal"
            sx={{
              position: "sticky",
              top: `${stackHeight}px`,
              left: 0,
              height: `calc(100vh - 40px - ${stackHeight}px)`,
            }}
          >
            <Paper
              elevation={1}
              sx={{
                height: "100%",
                width: "300px",
                minWidth: "300px",
                overflowY: "auto",
                padding: 2,
                boxSizing: "border-box",
                backgroundColor: "white",
                zIndex: 10,
              }}
            >
              <Stack direction={"column"} spacing={2}>
                {/* Filters label and Clear All button */}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6" fontWeight="bold">
                    Filters
                  </Typography>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={handleClearAllFilters}
                  >
                    Clear All
                  </Button>
                </Box>

                <Divider sx={{ my: 2 }}></Divider>

                <Stack direction={"column"} spacing={0}>
                  {/* AI Filter */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>Filter with AI</Typography>
                    <IconButton onClick={handleAIFilterToggle} size="small">
                      {isAIFilterExpanded ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </IconButton>
                  </Box>
                  <Collapse in={isAIFilterExpanded}>
                    <Box>
                      <TextField
                        variant="outlined"
                        value={aiFilterQuery}
                        onChange={handleAIFilterChange}
                        onFocus={handleAIFilterFocus}
                        onBlur={handleAIFilterBlur}
                        multiline
                        rows={3}
                        fullWidth
                        InputProps={{
                          style: { fontSize: "0.875rem" }, // Smaller font size
                        }}
                        placeholder={
                          isAIFilterFocused
                            ? ""
                            : "Example: Open tickets of happy customers that are related to billing and payment"
                        }
                      />
                      <Button
                        variant="outlined"
                        onClick={handleRunAIFilter}
                        fullWidth
                        sx={{ mt: 1 }}
                        disabled={isAIFilterLoading || !aiFilterQuery.trim()}
                      >
                        {isAIFilterLoading ? (
                          <CircularProgress size={24} />
                        ) : (
                          "Run"
                        )}
                      </Button>
                    </Box>
                  </Collapse>

                  {/* Divider with OR */}
                  <Divider sx={{ my: 1 }}>
                    <Typography variant="body2" color="text.secondary">
                      OR
                    </Typography>
                  </Divider>
                </Stack>
                {/* Search Bar */}
                <TextField
                  label="Search"
                  variant="outlined"
                  value={search}
                  onChange={handleSearchChange}
                />

                {/* Sorting Filter */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Sorting</Typography>
                  <IconButton onClick={handleSortingToggle} size="small">
                    {isSortingExpanded ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </IconButton>
                </Box>
                <Collapse in={isSortingExpanded}>
                  <Box sx={{ paddingLeft: 2 }}>
                    {/* Sort By Dropdown */}
                    <TextField
                      select
                      label="Sort By"
                      value={sortBy}
                      onChange={handleSortChange}
                      fullWidth
                      margin="normal"
                      size="small"
                    >
                      <MenuItem value="createdAt">Date created</MenuItem>
                      <MenuItem value="updatedAt">Date last modified</MenuItem>
                    </TextField>

                    {/* Sort Direction Dropdown */}
                    <TextField
                      select
                      label="Sort Direction"
                      value={sortDirection}
                      onChange={handleSortDirectionChange}
                      fullWidth
                      margin="normal"
                      size="small"
                    >
                      <MenuItem value="ASC">Ascending</MenuItem>
                      <MenuItem value="DESC">Descending</MenuItem>
                    </TextField>
                  </Box>
                </Collapse>

                {/* Status Filter */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Status</Typography>
                  <IconButton onClick={handleStatusToggle} size="small">
                    {isStatusExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </Box>
                <Collapse in={isStatusExpanded}>
                  <Box sx={{ paddingLeft: 2 }}>
                    <FormGroup>
                      {["OPEN", "IN_PROGRESS", "SOLVED", "CLOSED"].map(
                        (status) => (
                          <FormControlLabel
                            key={status}
                            control={
                              <Checkbox
                                checked={
                                  filters.status?.includes(status) || false
                                }
                                onChange={(e) => {
                                  const newValues = e.target.checked
                                    ? [...(filters.status || []), status]
                                    : (filters.status || []).filter(
                                        (v) => v !== status
                                      );
                                  handleFilterChange("status", newValues);
                                }}
                                size="small"
                              />
                            }
                            label={
                              <Typography
                                variant="body2"
                                sx={{ fontSize: "0.8rem" }}
                              >
                                {status.charAt(0) +
                                  status
                                    .slice(1)
                                    .toLowerCase()
                                    .replace("_", " ")}
                              </Typography>
                            }
                            sx={{
                              margin: 0,
                              "& .MuiCheckbox-root": {
                                padding: "2px",
                              },
                            }}
                          />
                        )
                      )}
                    </FormGroup>
                  </Box>
                </Collapse>

                {/* Priority Filter */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Priority</Typography>
                  <IconButton onClick={handlePriorityToggle} size="small">
                    {isPriorityExpanded ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </IconButton>
                </Box>
                <Collapse in={isPriorityExpanded}>
                  <Box sx={{ paddingLeft: 2 }}>
                    <FormGroup>
                      {["LOW", "MEDIUM", "HIGH", "URGENT"].map((priority) => (
                        <FormControlLabel
                          key={priority}
                          control={
                            <Checkbox
                              checked={
                                filters.priority?.includes(priority) || false
                              }
                              onChange={(e) => {
                                const newValues = e.target.checked
                                  ? [...(filters.priority || []), priority]
                                  : (filters.priority || []).filter(
                                      (v) => v !== priority
                                    );
                                handleFilterChange("priority", newValues);
                              }}
                              size="small"
                            />
                          }
                          label={
                            <Typography
                              variant="body2"
                              sx={{ fontSize: "0.8rem" }}
                            >
                              {priority.charAt(0) +
                                priority.slice(1).toLowerCase()}
                            </Typography>
                          }
                          sx={{
                            margin: 0,
                            "& .MuiCheckbox-root": {
                              padding: "2px",
                            },
                          }}
                        />
                      ))}
                    </FormGroup>
                  </Box>
                </Collapse>

                {/* Sentiment Range Filter */}
                <Typography>Sentiment</Typography>
                <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
                  <Slider
                    value={filters.sentiment || [0, 10]}
                    onChange={handleSentimentChange}
                    valueLabelDisplay="auto"
                    min={0}
                    max={10}
                    step={1}
                    marks
                    valueLabelFormat={(value) => value.toFixed(0)}
                  />
                </Box>

                {/* Intents Filter */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Intents</Typography>
                  <IconButton onClick={handleIntentsToggle} size="small">
                    {isIntentsExpanded ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </IconButton>
                </Box>
                <Collapse in={isIntentsExpanded}>
                  <Box sx={{ paddingLeft: 2 }}>
                    {intentCategories.map((category) => (
                      <IntentCategoryFilter
                        key={category.id}
                        category={category}
                        selectedIntents={filters.intents || []}
                        onIntentChange={handleFilterChange}
                      />
                    ))}
                  </Box>
                </Collapse>

                {/* Signals Filter */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Signals</Typography>
                  <IconButton onClick={handleSignalsToggle} size="small">
                    {isSignalsExpanded ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </IconButton>
                </Box>
                <Collapse in={isSignalsExpanded}>
                  <Box sx={{ paddingLeft: 2 }}>
                    {signalCategories.map((category) => (
                      <SignalCategoryFilter
                        key={category.id}
                        category={category}
                        selectedSignals={filters.signals || []}
                        onSignalChange={handleFilterChange}
                      />
                    ))}
                  </Box>
                </Collapse>

                {/* Requester Email Filter */}
                <TextField
                  label="Requester Email"
                  variant="outlined"
                  value={filters.requesterEmail || ""}
                  onChange={(e) =>
                    handleFilterChange("requesterEmail", e.target.value)
                  }
                />

                {/* Assignee Email Filter */}
                <TextField
                  label="Assignee Email"
                  variant="outlined"
                  value={filters.assigneeEmail || ""}
                  onChange={(e) =>
                    handleFilterChange("assigneeEmail", e.target.value)
                  }
                />
              </Stack>
            </Paper>
          </Collapse>
        </Box>
        {/* Ticket List */}
        <Box flexGrow={1} marginLeft={2} marginRight={2}>
          <Stack
            direction={"column"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            {isTicketsLoading ? (
              <LoadingSkeleton count={9} />
            ) : tickets.length !== 0 ? (
              <>
                <Grid
                  container
                  spacing={2}
                  paddingTop={"16px"}
                  paddingLeft={"16px"}
                >
                  <TicketList tickets={tickets} loadTickets={loadTickets} />
                </Grid>
                <Stack direction="row" spacing={2} my={"20px"}>
                  <Button
                    variant="contained"
                    onClick={handlePreviousPage}
                    disabled={pageNumber === 0}
                  >
                    Previous
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleNextPage}
                    disabled={
                      pageNumber >= Math.ceil(totalTickets / pageSize) - 1
                    }
                  >
                    Next
                  </Button>
                </Stack>
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "25vh",
                }}
              >
                <Typography variant="h6" color={"grey.500"}>
                  Create a ticket to start.
                </Typography>
              </Box>
            )}
          </Stack>
          <Fab
            color="primary"
            aria-label="add"
            onClick={handleOpenTicketForm}
            sx={{ position: "fixed", bottom: 25, right: 25 }}
          >
            <AddIcon />
          </Fab>
          <TicketForm
            open={isTicketFormOpen}
            handleClose={handleCloseTicketForm}
            onCreateTicket={handleCreateTicket}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TicketPage;
