import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Paper,
  Tooltip,
  Stack,
  Box,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { useAuth } from "@clerk/clerk-react";
import { createApiKey, fetchApiKeys, deleteApiKey } from "../../services/api";
import CreateApiKeyDialog from "../CreateApiKeyDialog";
import WarningDialog from "../ApiKeyWarningDialog";

const ApiKeysTab = () => {
  const [isCreateApiKeyDialogOpen, setIsCreateApiKeyDialogOpen] =
    useState(false);
  const [isApiKeyWarningDialogOpen, setIsApiKeyWarningDialogOpen] =
    useState(false);
  const [apiKey, setApiKey] = useState("");
  const [apiKeys, setApiKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { getToken } = useAuth();

  const loadApiKeys = useCallback(async () => {
    setIsLoading(true);
    const token = await getToken();
    const fetchedApiKeys = await fetchApiKeys(token);
    setApiKeys(fetchedApiKeys.apiKeys || []);
    setIsLoading(false);
  }, [getToken]);

  useEffect(() => {
    loadApiKeys();
  }, [loadApiKeys]);

  const handleOpenApiKeyDialog = () => {
    setIsApiKeyWarningDialogOpen(true);
  };

  const handleConfirmWarning = async () => {
    setIsApiKeyWarningDialogOpen(false);
    const response = await createApiKey(await getToken());
    response.apiKey && setApiKey(response.apiKey);
    setIsCreateApiKeyDialogOpen(true);
    loadApiKeys();
  };

  const handleDeleteApiKey = async (apiKeyId) => {
    const token = await getToken();
    const response = await deleteApiKey(apiKeyId, token);
    if (response) {
      setApiKeys(apiKeys.filter((key) => key.id !== apiKeyId));
    }
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{
          borderRadius: "32px",
          textTransform: "none",
          boxShadow: 0,
          my: 2,
        }}
        onClick={handleOpenApiKeyDialog}
      >
        <Typography variant="h7" color={"white"} sx={{ fontWeight: "bold" }}>
          Create API Key
        </Typography>
      </Button>

      <WarningDialog
        open={isApiKeyWarningDialogOpen}
        onClose={() => setIsApiKeyWarningDialogOpen(false)}
        onConfirm={handleConfirmWarning}
      />
      <CreateApiKeyDialog
        open={isCreateApiKeyDialogOpen}
        onClose={() => setIsCreateApiKeyDialogOpen(false)}
        apiKey={apiKey}
      />

      {isLoading ? (
        <Typography>Loading...</Typography>
      ) : apiKeys.length === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100px"
        >
          <Typography
            variant="h5"
            sx={{ textAlign: "center", color: "text.secondary" }}
          >
            You have no API keys created yet 😥
          </Typography>
        </Box>
      ) : (
        <List>
          {apiKeys.map((key) => (
            <ListItem key={key.id} sx={{ py: 1 }}>
              <Paper
                elevation={1}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 12px",
                  borderRadius: "8px",
                  backgroundColor: "#f7f7f7",
                  width: "100%",
                  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  sx={{ flexGrow: 1 }}
                >
                  <ListItemText
                    primary={
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "Monospace", fontWeight: "bold" }}
                      >
                        sk-actioner-************************
                      </Typography>
                    }
                    secondary={
                      <Stack>
                        <Typography variant="body2" color="textSecondary">
                          Created By: {key.createdBy.name} (
                          {key.createdBy.email})
                        </Typography>
                        <Typography>
                          Created at {new Date(key.createdAt).toLocaleString()}
                        </Typography>
                      </Stack>
                    }
                  />
                </Stack>
                <Tooltip title="Delete API Key">
                  <IconButton
                    edge="end"
                    onClick={() => handleDeleteApiKey(key.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Paper>
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
};

export default ApiKeysTab;
