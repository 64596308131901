import React, { useState, useCallback, useEffect } from "react";
import {
  Button,
  Typography,
  Stack,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  ContentCopy as ContentCopyIcon,
} from "@mui/icons-material";
import { useAuth } from "@clerk/clerk-react";
import {
  fetchWebhooks,
  createWebhook,
  deleteWebhook,
} from "../../services/api";

const WebhooksTab = () => {
  const [webhooks, setWebhooks] = useState([]);
  const [isCreateWebhookDialogOpen, setIsCreateWebhookDialogOpen] =
    useState(false);
  const [isDeleteWebhookDialogOpen, setIsDeleteWebhookDialogOpen] =
    useState(false);
  const [webhookToDelete, setWebhookToDelete] = useState(null);

  const { getToken } = useAuth();

  const loadWebhooks = useCallback(async () => {
    const token = await getToken();
    const fetchedWebhooks = await fetchWebhooks(token);
    setWebhooks(fetchedWebhooks.webhooks);
  }, [getToken]);

  useEffect(() => {
    loadWebhooks();
  }, [loadWebhooks]);

  const handleCreateWebhook = async () => {
    const token = await getToken();
    const response = await createWebhook(token);
    if (response) {
      setIsCreateWebhookDialogOpen(false);
      loadWebhooks();
    }
  };

  const handleDeleteWebhook = async () => {
    if (webhookToDelete) {
      const token = await getToken();
      const response = await deleteWebhook(webhookToDelete, token);
      if (response) {
        setIsDeleteWebhookDialogOpen(false);
        loadWebhooks();
      }
    }
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ my: 2 }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Manage Webhooks
        </Typography>
        <Button
          variant="contained"
          sx={{ borderRadius: "32px", textTransform: "none", boxShadow: 0 }}
          startIcon={<AddIcon />}
          onClick={() => setIsCreateWebhookDialogOpen(true)}
        >
          Create Webhook
        </Button>
      </Stack>
      {webhooks.length === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100px"
        >
          <Typography
            variant="h5"
            sx={{ textAlign: "center", color: "text.secondary" }}
          >
            You have no webhooks created yet 🫠
          </Typography>
        </Box>
      ) : (
        <List>
          {webhooks.map((webhook) => (
            <ListItem
              key={webhook.webhookId}
              sx={{
                borderTop: "0.5px solid #e0e0e0",
                borderBottom: "0.5px solid #e0e0e0",
                py: 1,
              }}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => {
                    setWebhookToDelete(webhook.webhookId);
                    setIsDeleteWebhookDialogOpen(true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemText
                primary={
                  <Paper
                    elevation={1}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "8px 12px",
                      borderRadius: "8px",
                      backgroundColor: "#f7f7f7",
                      width: "100%",
                      boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        flexGrow: 1,
                        marginRight: 1,
                        fontFamily: "Monospace",
                        color: "#333",
                      }}
                      title={webhook.url}
                    >
                      {webhook.url}
                    </Typography>
                    <Tooltip title="Copy to clipboard">
                      <IconButton
                        size="small"
                        onClick={() =>
                          navigator.clipboard.writeText(webhook.url)
                        }
                        aria-label="Copy URL"
                      >
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Paper>
                }
                secondary={
                  <>
                    <Typography variant="body2" color="textSecondary">
                      Created By: {webhook.createdBy.name} (
                      {webhook.createdBy.email})
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Created At: {new Date(webhook.createdAt).toLocaleString()}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      )}
      <Dialog
        open={isCreateWebhookDialogOpen}
        onClose={() => setIsCreateWebhookDialogOpen(false)}
      >
        <DialogTitle>Create Webhook</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to create a new webhook?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsCreateWebhookDialogOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleCreateWebhook} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isDeleteWebhookDialogOpen}
        onClose={() => setIsDeleteWebhookDialogOpen(false)}
      >
        <DialogTitle>Delete Webhook</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to delete this webhook?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteWebhookDialogOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleDeleteWebhook} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default WebhooksTab;
